<template>
	<Component
		:is="renderAs"
		class="social-icon"
		:to="socialMedia.url"
		:target="target"
		:aria-label="`Nos siga no ${capitalize(socialMedia.name)}`"
		rel="nofollow noopener"
	>
		<Component :is="icons[socialMedia.name]" />
	</Component>
</template>

<script setup lang="ts">
import type {
	AvailableSocialMedia,
	SocialMedia
} from '@SHARED/core/entities/SocialMedia';
import type { CssSize, ResponsiveSize } from '@SHARED/utils/helperTypes';
import { capitalize } from '@SHARED/utils';

import type { FunctionalComponent, SVGAttributes } from '#app/compat/capi';

import { NuxtLink } from '#components';
import FacebookIcon from '~icons/ic/baseline-facebook';
import LinkedinIcon from '~icons/mdi/linkedin';
import WhatsAppIcon from '~icons/mdi/whatsapp';
import YoutubeIcon from '~icons/mdi/youtube';
import InstagramIcon from '~icons/ri/instagram-fill';
import TwitterIcon from '~icons/ri/twitter-x-fill';

defineOptions({ name: 'SocialIcon' });

const icons: Record<
	AvailableSocialMedia,
	FunctionalComponent<SVGAttributes, {}, any>
> = {
	facebook: FacebookIcon,
	instagram: InstagramIcon,
	linkedin: LinkedinIcon,
	twitter: TwitterIcon,
	youtube: YoutubeIcon,
	whatsapp: WhatsAppIcon
};

type SocialIconProps = {
	socialMedia: SocialMedia;
	target?: HTMLAnchorElement['target'];
	renderAs?: keyof HTMLElementTagNameMap | typeof NuxtLink;
	iconSize?: ResponsiveSize | null;
};

const props = withDefaults(defineProps<SocialIconProps>(), {
	target: '_blank',
	renderAs: NuxtLink,
	iconSize: null
});

const mobileIconSize = computed<CssSize>(
	() => props.iconSize?.mobile || '1.75rem'
);

const desktopIconSize = computed<CssSize>(
	() => props.iconSize?.desktop || '1.75rem'
);
</script>

<style lang="scss" scoped>
$mobileIconSize: v-bind(mobileIconSize);
$desktopIconSize: v-bind(desktopIconSize);

$iconPaddingSize: 0.5rem;

.social-icon {
	border-radius: 50%;
	padding: $iconPaddingSize;
	width: calc($mobileIconSize + calc(2 * $iconPaddingSize));
	height: calc($mobileIconSize + calc(2 * $iconPaddingSize));
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;

	svg {
		width: $mobileIconSize;
		height: $mobileIconSize;
	}

	@include screen-up(lg) {
		width: calc($desktopIconSize + calc(2 * $iconPaddingSize));
		height: calc($desktopIconSize + calc(2 * $iconPaddingSize));

		svg {
			width: $desktopIconSize;
			height: $desktopIconSize;
		}
	}
}
</style>
