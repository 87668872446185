import type { CondosRepository } from '@SHARED/core/ports/repositories/CondosRepository';
import { Result } from '@SHARED/core/helpers/result';

export const nuxtCondosRepository: CondosRepository = {
	async getCondosFromCompany({ domain, maxQuantity, offset, filters }) {
		try {
			const query = {
				maxQuantity,
				offset,
				filters
			};

			const condos = await $fetch(`/api/websites/${domain}/condos`, {
				query,
				headers: { host: domain }
			});

			return Result.ok(
				condos || {
					filteredCount: 0,
					condos: [],
					totalCount: 0
				}
			);
		} catch (error) {
			return Result.error({
				message: `Error while fetching condos for domain ${domain}`,
				source: 'nuxtCondosRepository.getCondosFromCompany',
				originalErrorObject: error as Error
			});
		}
	},
	async getCondoBySlug({ domain, slug }) {
		try {
			const condo = await $fetch(`/api/websites/${domain}/condos/${slug}`, {
				headers: { host: domain }
			});

			return Result.ok(condo || null);
		} catch (error) {
			if ((error as any)?.response?.status === 404) return Result.ok(null);

			return Result.error({
				message: `Error while fetching single condo with slug ${slug}`,
				source: 'nuxtCondosRepository.getCondoBySlug',
				originalErrorObject: error as Error
			});
		}
	}
};
