import type {
	SocialMedia,
	AvailableSocialMedia
} from '@SHARED/core/entities/SocialMedia';

export const availableSocialMediaList = [
	'facebook',
	'instagram',
	'linkedin',
	'twitter',
	'whatsapp',
	'youtube'
] as const;

type SocialMediaWithAnyName = Omit<SocialMedia, 'name'> & {
	name: string;
};

export class SocialMediaModule {
	static getValidAndUniqueSocialMediaList(
		socialMediaList: SocialMediaWithAnyName[]
	): SocialMedia[] {
		return socialMediaList
			.map(socialMedia => ({
				name: socialMedia.name.trim().toLowerCase() as AvailableSocialMedia,
				url: socialMedia.url.trim()
			}))
			.filter((socialMedia, currentIndex) => {
				const isAvailable = availableSocialMediaList.includes(socialMedia.name);

				if (!isAvailable) return false;

				const indexOfFirstSocialMediaWithSameName = socialMediaList.findIndex(
					socialMediaToCompare => socialMedia.name === socialMediaToCompare.name
				);

				const isUnique = indexOfFirstSocialMediaWithSameName === currentIndex;

				return isUnique;
			});
	}
}
