<template>
	<div error-page>
		<HeaderSection v-bind="header.config" :transparent-on-top-of-page="false" />
		<ErrorSection
			:status-code="error.statusCode"
			message="Página não encontrada"
			subtitle="Desculpe, a página que você procura não existe ou foi movida."
		/>
		<FooterSection v-bind="footerProps" />
	</div>
</template>

<script setup lang="ts">
import type { FooterSection as IFooterSection } from '@SHARED/core/entities/sections/FooterSection';
import type {
	WebsiteConfig,
	WebsitePage
} from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize } from '@SHARED/utils/helperTypes';

import HeaderSection from '@SHARED/components/sections/HeaderSection.vue';
import FooterSection from '@SHARED/components/sections/FooterSection.vue';

import { PROPERTIES_REPOSITORY } from '@SHARED/utils/vueProvidersSymbols';
import { polishedColorService } from '@SHARED/adapters/services/color/polishedColorServiceAdapter';
import { phoneService } from '@SHARED/adapters/services/phone/phoneServiceAdapter';
import {
	COLOR_SERVICE,
	PHONE_SERVICE
} from '@SHARED/utils/vueProvidersSymbols';
import { nuxtPropertiesRepository } from '@/adapters/repositories/nuxt/nuxtPropertiesRepository';

import ErrorSection from '@/components/organisms/ErrorSection.vue';

provide(PROPERTIES_REPOSITORY, nuxtPropertiesRepository);
provide(COLOR_SERVICE, polishedColorService);
provide(PHONE_SERVICE, phoneService);

type ErrorPageProps = {
	error: {
		url: string;
		statusCode: number;
		statusMessage: string;
		message: string;
		stack: string;
	};
};

defineProps<ErrorPageProps>();

const nuxtAppData = useNuxtApp();

const websiteConfig = useState<WebsiteConfig>('websiteConfig');

const domain = useState<string>('domain');

if (process.server) {
	domain.value = nuxtAppData.ssrContext!.event.node.req.headers.host!;

	websiteConfig.value = await $fetch(`/api/websites/${domain.value}`, {
		headers: { host: domain.value }
	});
}

const pages = computed<WebsitePage[]>(() => websiteConfig.value.pages);

const pageConfig = computed<WebsitePage | undefined>(() =>
	pages.value?.find((page: WebsitePage) => page.name === 'ErrorPage')
);

const header = computed(() => websiteConfig.value.header);

const headerHeight = computed(() => {
	if (!header.value) return 0;

	return header.value.config.height;
});

const footer = computed(() => websiteConfig.value.footer);

const footerProps = computed<IFooterSection['config']>(() => {
	const footerConfig = footer.value.config;

	if (!pageConfig.value) return footerConfig;

	const { showFooterAboutSection, showFooterContactSection } = pageConfig.value;

	return {
		...footerConfig,
		aboutSection: showFooterAboutSection ? footerConfig.aboutSection : null,
		contactSection: showFooterContactSection
			? footerConfig.contactSection
			: null
	};
});

useState('header', () => header);
useState('footer', () => footer);
useState('domain', () => domain);

const {
	primary: primaryColor,
	secondary: secondaryColor,
	accent: accentColor,
	black: blackColor,
	white: whiteColor,
	offwhite: offwhiteColor,
	success: successColor,
	danger: dangerColor,
	lightgray: lightgrayColor,
	gray: grayColor,
	darkgray: darkgrayColor
} = websiteConfig.value.styles.colorPalette;

const sitePaddingMobile = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.mobile || '5%'
);

const sitePaddingTablet = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.tablet || '7.5%'
);

const sitePaddingDesktop = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.desktop || '10%'
);

const { fontFamily: defaultFontFamily } =
	websiteConfig.value.styles.typography.default;

const { fontFamily: headingFontFamily } =
	websiteConfig.value.styles.typography.heading;

useHeadSafe({ title: () => `${websiteConfig.value.company.name} | 404` });
</script>

<style lang="postcss" scoped>
[error-page] {
	--sm-site-padding: v-bind(sitePaddingMobile);
	--md-site-padding: v-bind(sitePaddingTablet);
	--lg-site-padding: v-bind(sitePaddingDesktop);

	--header-height: v-bind(headerHeight);

	/* <!-- TODO: renomear para adicionar o sufixo `-color` --> */
	--primary: v-bind(primaryColor);
	--secondary: v-bind(secondaryColor);
	--accent: v-bind(accentColor);

	--black: v-bind(blackColor);
	--white: v-bind(whiteColor);
	--offwhite: v-bind(offwhiteColor);
	--danger: v-bind(dangerColor);
	--success: v-bind(successColor);
	--lightgray: v-bind(lightgrayColor);
	--gray: v-bind(grayColor);
	--darkgray: v-bind(darkgrayColor);

	--default-font: v-bind(defaultFontFamily), Ubuntu, system-ui, sans-serif;
	--heading-font: v-bind(headingFontFamily), TimesNewRoman, 'Times New Roman',
		Times, Baskerville, Georgia, serif;

	--error-section-wrapper-height: 100vh;

	.heading-font {
		font-family: var(--heading-font);
	}

	.default-font {
		font-family: var(--default-font);
	}

	.page-padding {
		transition: all 0.2s ease-in-out;
		padding-top: 0;
	}

	.page-padding.active {
		/* <!-- TODO: não seria melhor usar essa variável dentro dos componentes que precisam dela ao invés de usar a classe? --> */
		padding-top: var(--header-height);
	}
}

.error-page-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: var(--white);
	background-size: cover;
	background-position: center;
	gap: 2rem;

	.error-code {
		font-size: 7.5rem;
		line-height: 110%;

		@include screen-up(lg) {
			font-size: 12.5rem;
		}
	}

	.buttons-wrapper {
		display: flex;
		gap: 1rem;

		.redirection-button {
			font-weight: 500;
		}
	}

	.error-title,
	.error-description,
	.error-code {
		text-align: center;
	}

	.error-information-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		.error-title {
			font-size: 1.75rem;

			@include screen-up(lg) {
				font-size: 2.75rem;
			}
		}

		.error-description {
			font-size: 1.25rem;

			@include screen-up(lg) {
				font-size: 1.5rem;
			}
		}
	}
}
</style>

<!--
TODO:
- migrar o conteúdo da página pro componente HeroSection (quando estivermos usando o `content`)
- descobrir como vamos receber imagens/configuração dessa página (já que não configuramos as seções dela pelo JSON)
-->
