<template>
	<template
		v-for="(paragraph, index) in paragraphs"
		:key="`${componentId}:${index}`"
	>
		<p v-if="paragraph">{{ paragraph }}</p>
		<div v-else>&nbsp;</div>
	</template>
</template>

<script setup lang="ts">
import { useId } from '@SHARED/composables/useId';

defineOptions({ name: 'ParagraphsWithLineBreak' });

const props = defineProps<{ text: string }>();

const componentId = useId('text-with-line-break', props.text);

const paragraphs = computed<string[]>(() =>
	props.text
		.trim()
		.replace(/\\n/g, '\n')
		.split('\n')
		.map(paragraph => paragraph.trim())
);
</script>

<style scoped lang="scss"></style>
