export default defineNuxtRouteMiddleware(to => {
	if (process.server) return;

	const domain = useState<string>('domain');
	if (
		domain.value.includes('kollerco.com.br') &&
		['PropertyPage', 'PropertiesListing'].includes(to.name)
	) {
		return navigateTo('https://kollerco.com.br' + to.path.toLowerCase(), {
			external: true
		});
	}
});
