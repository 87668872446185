<template>
	<div
		v-if="shouldShowConsentModal"
		class="cookie-consent-modal-wrapper container"
	>
		<div
			class="cookie-consent-modal-content"
			:data-rounded="styles.appearance.defaultBorderRadius"
		>
			<span class="cookie-consent-text default-font">
				Usamos cookies para oferecer uma experiência melhor a você. Ao
				prosseguir, você está ciente da nossa
				<NuxtLink
					class="cookie-policy-link"
					:to="{
						name: 'PrivacyPolicy',
						hash: '#cookies'
					}"
				>
					Política de cookies
				</NuxtLink>
			</span>
			<ButtonBlock
				text="Aceitar cookies"
				:is-outlined="false"
				class="accept-cookies-button"
				@click="acceptCookies"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useLocalStorage } from '@vueuse/core';

import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';

defineOptions({ name: 'CookieConsentModal' });

const styles = useState<WebsiteStyles>('styles');

const isWebsiteUsingCookies = useState<boolean>('isWebsiteUsingCookies');

const hasConsentToCookies = useLocalStorage<boolean>('cookieConsent', false);

const hasMountedComponent = ref<boolean>(false);

const shouldShowConsentModal = computed<boolean>(
	() =>
		hasConsentToCookies.value !== true &&
		hasMountedComponent.value &&
		isWebsiteUsingCookies.value
);

function acceptCookies() {
	hasConsentToCookies.value = true;
}

onMounted(() => {
	hasMountedComponent.value = true;
});
</script>

<style lang="scss" scoped>
.cookie-consent-modal-wrapper {
	bottom: 2rem;
	z-index: 30;
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: center;

	.cookie-consent-modal-content {
		background-color: var(--white);
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 1rem;
		box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

		@include screen-up(md) {
			flex-direction: row;
			gap: 1.5rem;
		}

		@include border-radius-size($pill: var(--rounded-border-radius));

		.cookie-consent-text {
			font-size: 0.875rem;
			font-weight: 400;

			.cookie-policy-link {
				text-decoration: underline;
			}
		}

		.accept-cookies-button {
			font-size: 0.875rem;
			font-weight: 600;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-wrap: nowrap;

			@include screen-up(md) {
				width: auto;
			}
		}
	}
}
</style>
