import { useChallengeV2 } from '@nargarath/vue-recaptcha';

type UseRecaptchaParams = {
	onVerified: () => void | Promise<void>;
};

type UseRecaptchaReturn = {
	recaptchaToken: Ref<string | null>;
	recaptchaRoot: Ref<Element | undefined>;
	resetRecaptcha: () => void;
	executeRecaptchaChallenge: () => void;
};

export function useRecaptcha({
	onVerified
}: UseRecaptchaParams): UseRecaptchaReturn {
	const recaptchaToken = ref<string | null>(null);

	const {
		root: recaptchaRoot,
		execute: executeRecaptchaChallenge,
		onVerify: onCaptchaVerified,
		onExpired: onCatchaExpired,
		reset: resetCaptchaComposable
	} = useChallengeV2({
		options: {
			size: 'invisible'
		}
	});

	function resetRecaptcha() {
		resetCaptchaComposable();
		recaptchaToken.value = null;
	}

	onCaptchaVerified(token => {
		recaptchaToken.value = token;
		onVerified();
	});

	onCatchaExpired(() => {
		recaptchaToken.value = null;
	});

	return {
		recaptchaToken,
		recaptchaRoot,
		resetRecaptcha,
		executeRecaptchaChallenge
	};
}
