export type Animation =
	| 'slide-down'
	| 'slide-up'
	| 'slide-left'
	| 'slide-right';

export type AnimationHelper = 'page-padding';

type AnimationStatus = 'start' | 'end';

type UseAnimationProps = {
	animationStyle: MaybeRef<Animation | AnimationHelper>;
	animationStatus?: AnimationStatus;
};

// TODO: não seria melhor renomear para deixar mais claro que são animações relacinadas ao cabeçalho?
const animations: Record<
	Animation | AnimationHelper,
	{ start: string; end: string }
> = {
	'slide-down': {
		start: '-translate-y-full',
		end: 'translate-y-0'
	},
	'slide-up': {
		start: 'translate-y-full',
		end: 'translate-y-0'
	},
	'slide-left': {
		start: 'translate-x-full',
		end: 'translate-x-0'
	},
	'slide-right': {
		start: '-translate-x-full',
		end: 'translate-x-0'
	},
	'page-padding': {
		start: 'page-padding active',
		end: 'page-padding'
	}
};

// TODO: entender melhor como esse composable funciona
export function useAnimation({
	animationStyle,
	animationStatus = 'start'
}: UseAnimationProps) {
	const animationClasses = ref(getAnimationClass(animationStatus));

	function getAnimationClass(animationStatus: AnimationStatus): string {
		const animationStyleValue = unref(animationStyle);

		return animations[animationStyleValue][animationStatus];
	}

	return {
		getAnimationClass,
		animationClasses
	};
}
