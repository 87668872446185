import type { InjectionKey } from 'vue';

import type { SystemEnumsRepository } from '@SHARED/core/ports/repositories/SystemEnumsRepository';
import type { CondosRepository } from '@SHARED/core/ports/repositories/CondosRepository';
// import type { ColorService } from '@SHARED/core/ports/services/ColorService';
// import type { PhoneService } from '@SHARED/core/ports/services/PhoneService';
import type { BlogPostsRepository } from '@SHARED/core/ports/repositories/BlogPostsRepository';

// export const COLOR_SERVICE: InjectionKey<ColorService> = Symbol('colorService');
// export const PHONE_SERVICE: InjectionKey<PhoneService> = Symbol('phoneService');

export const CONDOS_REPOSITORY: InjectionKey<CondosRepository> =
	Symbol('condosRepository');

export const SYSTEM_ENUMS_REPOSITORY: InjectionKey<SystemEnumsRepository> =
	Symbol('systemEnumsRepository');

export const BLOG_POSTS_REPOSITORY: InjectionKey<BlogPostsRepository> = Symbol(
	'blogPostsRepository'
);
