import type { WebsiteConfig } from '@SHARED/core/entities/WebsiteConfig';

type GoogleIntegrationsScript = { innerHTML: string } | { src: string };

export default defineNuxtPlugin(({ $config }) => {
	const { recaptcha } = $config.public;

	const websiteConfig = useState<WebsiteConfig>('websiteConfig');

	if (websiteConfig.value.integrations.googleAnalyticsId) {
		const { initialize, enableAnalytics } = useGtag();

		initialize(websiteConfig.value.integrations.googleAnalyticsId);
		enableAnalytics(websiteConfig.value.integrations.googleAnalyticsId);
	}

	const googleTagManagerScript = computed<GoogleIntegrationsScript[]>(() => {
		if (!websiteConfig.value.integrations.googleTagManagerId) return [];

		return [
			{
				innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${websiteConfig.value.integrations.googleTagManagerId}');`,
				key: 'GTMScript'
			}
		];
	});

	const googleTagManagerNoScript = computed<GoogleIntegrationsScript[]>(() => {
		if (!websiteConfig.value.integrations.googleTagManagerId) return [];

		return [
			{
				innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${websiteConfig.value.integrations.googleTagManagerId}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`,
				key: 'googleTagManagerNoScript'
			}
		];
	});

	const scripts = computed(() => [
		{
			src: `https://www.google.com/recaptcha/enterprise.js?render=${recaptcha.v2SiteKey}&onload=__vueRecaptchaLoaded`,
			defer: true,
			key: 'recaptcha'
		},
		...googleTagManagerScript.value
	]);

	const noScripts = computed(() => [...googleTagManagerNoScript.value]);

	const isWebsiteUsingCookies = computed<boolean>(() => {
		const { googleAnalyticsId, googleTagManagerId } =
			websiteConfig.value.integrations;

		return !!(googleAnalyticsId || googleTagManagerId);
	});

	useState('isWebsiteUsingCookies', () => isWebsiteUsingCookies);

	useHead({
		script: () => scripts.value,
		noscript: () => noScripts.value
	});
});
