// TODO: renomear essa composable para não dar warnings no lint
export function useId<Prefix extends string>(
	prefix: Prefix,
	deterministicId?: string
): `${Prefix}-${number | string}` {
	if (deterministicId) return `${prefix}-${deterministicId}`;

	const idCounters = useState<Map<Prefix, number>>(
		'useId:idCounters',
		() => new Map<Prefix, number>()
	);

	const newCounterValue = idCounters.value.has(prefix)
		? idCounters.value.get(prefix)! + 1
		: 0;

	idCounters.value.set(prefix, newCounterValue);

	return `${prefix}-${newCounterValue}`;
}
