import { whenever } from '@vueuse/core';

import type {
	PageMeta,
	WebsiteConfig,
	WebsitePage
} from '@SHARED/core/entities/WebsiteConfig';
import { getUrlWithHttps } from '@SHARED/utils/url';

type PageMetaKey = keyof PageMeta;

type MetaFieldName =
	| 'title'
	| 'description'
	| 'image'
	| 'url'
	| 'type'
	| PageMetaKey;
type OgField = `og:${MetaFieldName}`;
type TwitterField = `twitter:${MetaFieldName | 'domain' | 'card'}`;

export type MetaTagObject = {
	content: string;
} & (
	| { name: MetaFieldName | 'color-scheme'; property?: OgField; key: string }
	| { property: 'og:locale'; key: 'og:locale' }
	| { property: TwitterField; key: TwitterField }
);

const ogFields: MetaFieldName[] = ['title', 'description', 'image'];

export function useWebsiteMetadata(
	pageMetaRefOrValue: MaybeRef<PageMeta | null> = null
): void {
	const pageMeta = ref(pageMetaRefOrValue);

	const route = useRoute();

	const websiteConfig = useState<WebsiteConfig>('websiteConfig');

	const pages = computed<WebsitePage[]>(() => websiteConfig.value.pages);

	const pageConfigMeta = computed<PageMeta | null>(() => {
		const pageConfig = pages.value?.find(page => page.name === route.name);

		return pageConfig?.meta || null;
	});

	const primaryColor = computed(
		() => websiteConfig.value.styles.colorPalette.primary
	);

	const websiteCompanyLogoUrl = computed<string>(
		() => websiteConfig.value.meta.image!
	);

	const domain = useState<string>('domain');

	const currentPageUrl = computed<string>(
		() => getUrlWithHttps(domain.value) + route.path
	);

	const staticPageMeta = computed<MetaTagObject[]>(() => [
		{ property: 'og:locale', content: 'pt_BR', key: 'og:locale' },
		{ name: 'color-scheme', content: primaryColor.value, key: 'color-scheme' },
		{
			name: 'url',
			property: 'og:url',
			content: currentPageUrl.value,
			key: 'og:url'
		},
		{ name: 'type', property: 'og:type', content: 'website', key: 'og:type' },
		{
			name: 'image',
			property: 'og:image',
			content: websiteCompanyLogoUrl.value,
			key: 'og:image'
		},
		{
			property: 'twitter:card',
			content: 'summary_large_image',
			key: 'twitter:card'
		},
		{
			property: 'twitter:url',
			content: currentPageUrl.value,
			key: 'twitter:url'
		},
		{ property: 'twitter:domain', content: domain.value, key: 'twitter:domain' }
	]);

	const pageMetaContent = computed<MetaTagObject[]>(() => {
		if (!pageMeta.value && !pageConfigMeta.value) return staticPageMeta.value;

		const metaDataKeys = Object.keys({
			...(pageConfigMeta.value || {}),
			...(pageMeta.value || {})
		}) as PageMetaKey[];

		const metaTags: MetaTagObject[] = [];

		metaDataKeys.forEach(key => {
			const pageMetaContent = pageMeta.value?.[key];
			const pageConfigMetaContent = pageConfigMeta.value?.[key];
			const globalMetaContent = websiteConfig.value.meta?.[key];

			const content =
				pageMetaContent || pageConfigMetaContent || globalMetaContent;

			if (!content) return;

			const currentKeyIsAlsoAnOgField = ogFields.includes(key);

			if (currentKeyIsAlsoAnOgField) {
				const ogKey: OgField = `og:${key}`;
				const twitterKey: TwitterField = `twitter:${key}`;

				metaTags.push({ name: key, property: ogKey, content, key: ogKey });
				metaTags.push({ property: twitterKey, content, key: twitterKey });
			} else {
				metaTags.push({ name: key, content, key });
			}
		});

		const newMetaTagsKeys = metaTags.map(({ key }) => key);

		const newMetadata = staticPageMeta.value
			.filter(({ key }) => !newMetaTagsKeys.includes(key))
			.concat(metaTags);

		return newMetadata;
	});

	const pageTitle = computed(() => {
		const receivedPageTitle = pageMeta.value?.title?.trim();

		if (receivedPageTitle) return receivedPageTitle;

		const pageConfigTitle = pageConfigMeta.value?.title?.trim();

		if (pageConfigTitle) return pageConfigTitle;

		const routePageTitle = route.meta.pageTitle as string | null | undefined;

		const fallbackTitle =
			routePageTitle?.trim() || websiteConfig.value.meta.title?.trim();

		return fallbackTitle
			? `${websiteConfig.value.company.name} | ${fallbackTitle.trim()}`
			: websiteConfig.value.company.name;
	});

	const nuxtApp = useNuxtApp();

	const head = nuxtApp.vueApp._context.provides.usehead;

	whenever(
		() => websiteConfig.value.meta.favicon,
		href => {
			head.push({
				title: pageTitle,
				htmlAttrs: { lang: 'pt-BR' },
				link: [
					{
						key: 'favicon',
						rel: 'icon',
						color: primaryColor.value,
						type: 'image/svg+xml',
						href
					}
				]
			});
		},
		{ immediate: true }
	);

	useHead({
		title: () => pageTitle.value,
		htmlAttrs: { lang: 'pt-BR' },
		// TODO: adicionar key para cada meta (em todos os lugares que usam meta) para evitar duplicação de tags
		meta: () => pageMetaContent.value
	});
}
