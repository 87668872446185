<template>
	<Component
		v-bind="$attrs"
		:is="tag"
		:type="type"
		:value="modelValue ? LabelPresenter.maskValue(modelValue, mask) : ''"
		@input="handleInput"
	/>
</template>

<script setup lang="ts">
import { LabelPresenter } from '@SHARED/presenters/LabelPresenter';

defineOptions({ name: 'InputField' });

type ParsingOption = 'number' | 'default';

const parsingDictionary: Record<ParsingOption, Function> = {
	number: (value: string) => +value.replace(/\D/g, ''),
	default: (value: string) => value
};

const timeTokens = {
	H: { pattern: /[0-2]/ },
	M: { pattern: /[0-5]/ }
};

type Emits = {
	(e: 'update:modelValue', value: string): void;
};

type InputFieldProps = {
	tag?: 'input' | 'textarea';
	type?: 'text' | 'email' | 'number' | 'file' | 'tel' | 'date' | 'time';
	mask?: string | string[];
	isReverseMask?: boolean;
	modelValue: string | number | null;
	parser?: ParsingOption;
};

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<InputFieldProps>(), {
	tag: 'input',
	type: 'text',
	mask: '',
	isReverseMask: false,
	parser: 'default'
});

const mask = computed(() => ({
	mask: props.mask,
	reversed: props.isReverseMask,
	tokens: {
		...timeTokens
	}
}));

function handleInput(event: Event): void {
	const element = event.target as HTMLInputElement | HTMLTextAreaElement;

	if (!props.mask) {
		return emit('update:modelValue', element.value);
	}

	const maskedValue = LabelPresenter.maskValue(element.value, mask.value);

	element.value = maskedValue;

	const parser = parsingDictionary[props.parser];

	emit('update:modelValue', parser(maskedValue));
}
</script>
