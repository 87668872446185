import type { MaskOptions } from 'maska';
import { Mask } from 'maska';

import type { IconComponent } from '@SHARED/presenters/PropertyPresenter';

export type PresenterLabel = {
	label: string;
	value: string | number;
	icon: IconComponent | null;
};

export const PLACEHOLDER_IMAGE_URL =
	'https://blintz-properties-sandbox.s3.us-east-2.amazonaws.com/placeholder-img.jpg';

export class LabelPresenter {
	static maskValue(value: string | number, mask: MaskOptions): string {
		if (!value) return '';
		const maska = new Mask(mask);
		return maska.masked(String(value) || '');
	}
}
