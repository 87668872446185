export function getUrlWithHttps(url: string): string {
	const urlWithoutTrailingSlash = url.endsWith('/') ? url.slice(0, -1) : url;

	if (urlWithoutTrailingSlash.startsWith('https://')) {
		return urlWithoutTrailingSlash;
	}

	if (urlWithoutTrailingSlash.startsWith('http://')) {
		return urlWithoutTrailingSlash.replace('http://', 'https://');
	}

	return `https://${urlWithoutTrailingSlash}`;
}

// COMPANY_ID.hadid-preview.pilardns.net e COMPANY_ID.hadid-sandbox-preview.pilardns.net (edited)
export function extractCompanyIdFromPreviewDomain(url: string): string | null {
	if (!url.includes('.')) {
		return null;
	}
	const clearedURL = removeUrlPrefixes(url);
	const match = clearedURL.match(/^([^.]+)/);
	if (match) {
		return match[1];
	}
	return null;
}

export function removeUrlPrefixes(domain: string): string {
	return domain.replace(
		/^(https?:\/\/)?(www\.|minisites\.)?(www\.|minisites\.)?/,
		''
	);
}

export type DomainVariantsTuple = [string, string, string];

export function getDomainVariants(domain: string): {
	website: DomainVariantsTuple;
	minisite: DomainVariantsTuple;
} {
	const domainWithoutPrefix = removeUrlPrefixes(domain);
	const httpsDomain = getUrlWithHttps(domainWithoutPrefix);
	const wwwDomain = getUrlWithHttps(`www.${domainWithoutPrefix}`);
	const minisiteDomain = getUrlWithHttps(`minisites.${domainWithoutPrefix}`);

	const website: DomainVariantsTuple = [
		domainWithoutPrefix,
		httpsDomain,
		wwwDomain
	];

	const minisite: DomainVariantsTuple = [
		domainWithoutPrefix,
		minisiteDomain,
		httpsDomain
	];

	return {
		website,
		minisite
	};
}

export function isValidHttpUrl(string: string): boolean {
	try {
		const url = new URL(string);

		return url.protocol === 'http:' || url.protocol === 'https:';
	} catch {
		return false;
	}
}

const removeTrailingSlashes = (str: string) => str.replace(/\/+$/, '');
const removeLeadingSlashes = (str: string) => str.replace(/^\/+/, '');

export function concatUrlPath(url: string, path: string): string {
	return `${removeTrailingSlashes(url)}/${removeLeadingSlashes(path)}`;
}

export function getUrlForExternalContext(
	isExternalContext: boolean,
	path: string,
	url: string
): string {
	if (!isExternalContext) return path;

	return concatUrlPath(url, path);
}
