import type { Company } from '@SHARED/core/entities/Company';

import { removeUndefinedValuesFromObject } from '@SHARED/utils';

type BaseParams = {
	utm_source: string;
	utm_medium: string;
};

type CampaignParams = {
	utm_campaign: string;
	utm_content: string;
};

type UTMParamsWithCampaign = BaseParams & CampaignParams;

type UTMParams = BaseParams | UTMParamsWithCampaign;

type UsePilarWebsiteUTMReturn = {
	utmParams: ComputedRef<string>;
};

type Campaign = {
	name: string | null;
	content: string | null;
};

type UsePilarWebsiteUTMParams = {
	context: 'minisite' | 'website';
};

const utmContextDictionary: Record<
	UsePilarWebsiteUTMParams['context'],
	string
> = {
	minisite: 'Minisite',
	website: 'Site'
};

export function usePilarWebsiteUtm({
	context
}: UsePilarWebsiteUTMParams): UsePilarWebsiteUTMReturn {
	const company = useState<Company>('company');

	const campaignName = useState<string | null>('campaignName', () => null);

	const activePropertyCommercialId = useState<null | string>(
		'activePropertyCommercialId',
		() => null
	);

	const campaign = computed<Campaign | null>(() => {
		if (!campaignName.value) return null;

		return {
			name: campaignName.value,
			content: activePropertyCommercialId.value
		};
	});

	const contextLabel = computed<string>(() => utmContextDictionary[context]);

	const utmParams = computed<string>(() => {
		const source = `${contextLabel.value} ${company.value.name}`;

		const queryParams = {
			utm_source: source,
			utm_medium: `${contextLabel.value} marca`,
			utm_campaign: campaign.value?.name || undefined,
			utm_content: campaign.value?.content || undefined
		};

		const query: UTMParams = removeUndefinedValuesFromObject(queryParams);

		return new URLSearchParams(query).toString();
	});

	return { utmParams };
}
