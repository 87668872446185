import { verifyValueBasedOnRegExes } from '@SHARED/utils/phone';

export function usePhoneInputValidation(phoneNumberGetter: () => string) {
	const phone = computed<string>(phoneNumberGetter);

	const brazilianPhoneMasks = ['(##) #####-####', '(##) ####-####'];

	const phoneMasks = ref<string | string[]>(brazilianPhoneMasks);

	function updateMask(newMask: string | string[]): void {
		phoneMasks.value = newMask;
	}

	function isValidPhone(): boolean {
		return verifyValueBasedOnRegExes(phoneMasks.value, phone.value);
	}

	return { updateMask, isValidPhone, phoneMasks };
}
