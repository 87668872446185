<template>
	<div
		class="modal-content-wrapper"
		:data-has-scrollable-body="hasScrollableBody"
	>
		<div class="modal-header">
			<span class="modal-title heading-font">
				{{ title }}
			</span>
			<button type="button" class="close-icon" @click="emit('close-modal')">
				<CloseIcon />
			</button>
		</div>

		<div class="modal-body">
			<slot name="body"></slot>
		</div>

		<div class="modal-footer">
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import CloseIcon from '~icons/mdi/close';

type ModalContentProps = {
	title: string;
	hasScrollableBody?: boolean;
};

withDefaults(defineProps<ModalContentProps>(), {
	hasScrollableBody: false
});

type Emits = {
	(e: 'close-modal'): void;
};

const emit = defineEmits<Emits>();
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/scrollbar.scss';

.modal-content-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
}

.modal-header {
	padding: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 0px solid var(--lightgray);
	border-bottom-width: 1px;

	.modal-title {
		font-size: 1.75rem;
	}

	.close-icon {
		transition: all 200ms ease-in-out;

		svg {
			width: 2.25rem;
			height: 2.25rem;
		}

		&:hover {
			transform: scale(1.1);
		}
	}
}

.modal-footer {
	padding: 1rem 1.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border: 0px solid var(--lightgray);
	border-top-width: 1px;
	gap: 1rem;
}

.modal-body {
	padding: 1.75rem 1.5rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

[data-has-scrollable-body='true'] {
	@include screen-up(md) {
		&.modal-content-wrapper {
			height: 100%;
			max-height: 90vh;
		}
	}

	.modal-body {
		@include scrollbar-styling();

		overflow-y: auto;
	}
}
</style>
