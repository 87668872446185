import 'notyf/notyf.min.css';

import { Notyf } from 'notyf';

import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';

let notyf: Notyf | null = null;

export function useNotyf(): Notyf {
	if (notyf) return notyf;

	const styles = useState<WebsiteStyles>('styles');

	notyf = new Notyf({
		duration: 3000,
		ripple: false,
		position: {
			x: 'center',
			y: 'top'
		},
		types: [
			{
				type: 'error',
				background: styles.value.colorPalette.danger
			},
			{
				type: 'info',
				background: styles.value.colorPalette.primary
			},
			{
				type: 'success',
				background: styles.value.colorPalette.success
			}
		]
	});

	return notyf;
}
