import type { WebsiteConfig } from '@SHARED/core/entities/WebsiteConfig';

type UseGoogleAnalyticsReturn = {
	trackGAEvent: (eventName: string, params: Record<string, any>) => void;
};

export function useGoogleAnalytics(): UseGoogleAnalyticsReturn {
	const websiteConfig = useState<WebsiteConfig>('websiteConfig');

	const { gtag: trackEvent } = useGtag();

	function trackGAEvent(eventName: string, params: Record<string, any>) {
		if (!websiteConfig.value.integrations.googleAnalyticsId) return;

		trackEvent('event', eventName, params);
	}

	return { trackGAEvent };
}
