<template>
	<div class="form-field-container" :class="{ invalid: isInvalid }">
		<div class="label-error-container">
			<label
				class="default-label"
				:class="[labelClass, { required }]"
				:for="id"
			>
				{{ label }}
			</label>
			<span v-show="errorMessage" class="default-font">
				{{ errorMessage }}
			</span>
		</div>

		<Component :is="icon" v-if="icon" :class="iconClass" />
		<slot :id="id"> </slot>
	</div>
</template>

<script setup lang="ts">
import { useId } from '@SHARED/composables/useId';

import type { IconComponent } from '@SHARED/presenters/PropertyPresenter';

defineOptions({ name: 'FormField' });

type FormField = {
	label: string;
	errorMessage?: string | Ref<string>;
	labelClass?: string;
	inputClass?: string;
	iconClass?: string;
	required?: boolean;
	// <!-- TODO: entender melhor como esse mecanismo funciona e deixar mais claro no código -->
	mask?: string | string[];
	icon?: IconComponent | null;
};

const props = withDefaults(defineProps<FormField>(), {
	errorMessage: '',
	labelClass: 'default-label',
	inputClass: 'default-input',
	mask: '',
	required: false,
	icon: null,
	iconClass: 'default-input-icon'
});

const isInvalid = computed<boolean>(() => !!props.errorMessage);

const id = useId(
	'form-field',
	`${props.label}-${props.labelClass}-${props.inputClass}-${props.mask}-${props.required}`
);
</script>

<style lang="scss">
@import '@SHARED/assets/style/mixins/scrollbar.scss';

.form-field-container {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;

	input,
	textarea {
		transition: all 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);

		&:focus {
			outline: none;
		}
	}

	&.invalid {
		* input[type='checkbox'],
		input,
		textarea,
		div {
			border-color: var(--danger);
		}
	}
}

.contact-label {
	font-size: 0.8rem;
	color: inherit;
	opacity: 0.8;
}

.contact-textarea,
.contact-input {
	width: 100%;
	padding: 1rem 0;
	color: var(--white);
	border-style: solid;
	border-color: var(--white);
	background: none;
	font-size: 0.9rem;

	opacity: 0.6;

	&:focus {
		opacity: 1;
	}
}

.contact-input {
	height: 1rem;
	border-bottom-width: 1px;
}

.contact-textarea {
	padding: 0.5rem;
	border-width: 1px;

	@include scrollbar-styling();
}

.label-error-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 1rem;

	span {
		font-size: 0.8rem;
		color: var(--danger);
		text-wrap: wrap;
	}

	.required::after {
		content: ' *';
	}
}

.campaign-label {
	font-family: var(--default-font);
	color: var(--black);
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 500;
}

.default-input-icon {
	position: absolute;
	width: 1.25rem;
	height: 1.25rem;
	left: 0.75rem;
	bottom: 0.75rem;
	color: #666;

	&-hidden-mobile {
		position: absolute;
		width: 1.25rem;
		height: 1.25rem;
		left: 0.75rem;
		bottom: 0.75rem;
		color: #666;

		@include screen-down(sm) {
			display: none;
		}
	}
}
</style>
