import type {
	BlockableRouteNames,
	GlobalWebsiteSettings
} from '@SHARED/core/entities/WebsiteConfig';

export default defineNuxtPlugin(() => {
	const blockableRouteNames: BlockableRouteNames[] = [
		'PropertiesListing',
		'WhoWeAre',
		'SendProperty',
		'CondosListing',
		'CondoPage'
	];

	const blockedRoutes = useState<BlockableRouteNames[]>('blockedRoutes');

	const globalSettings = useState<GlobalWebsiteSettings>('globalSettings');

	const router = useRouter();

	for (const routeName of blockableRouteNames) {
		if (blockedRoutes.value?.includes(routeName)) {
			router.removeRoute(routeName);
		}
	}

	if (globalSettings.value.enableCondos) return;

	const condoRoutes: BlockableRouteNames[] = ['CondosListing', 'CondoPage'];

	for (const routeName of condoRoutes) {
		router.removeRoute(routeName);
	}
});
