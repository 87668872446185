import type { Organization, ImageObject, WithContext } from 'schema-dts';
import type { Company } from '@SHARED/core/entities/Company';
import type { WebsiteConfig } from '@SHARED/core/entities/WebsiteConfig';

import { getUrlWithHttps } from '@SHARED/utils/url';
import { phoneService } from '@SHARED/adapters/services/phone/phoneServiceAdapter';

export function useOrganizationJsonLd(): ComputedRef<WithContext<Organization> | null> {
	const domain = useState<string>('domain');
	const company = useState<Company>('company');
	const websiteConfig = useState<WebsiteConfig>('websiteConfig');

	const organizationJsonLd = computed<WithContext<Organization> | null>(() => {
		if (!company.value || !websiteConfig.value) return null;

		const image =
			company.value.websiteConfig?.meta?.image ||
			company.value.websiteConfig?.meta?.favicon;

		const logo: ImageObject = {
			'@type': 'ImageObject',
			url: image || undefined
		};

		const phone = phoneService
			.getPhoneValue(company.value.phone.replace('+55', ''))
			.trim();

		return {
			'@type': 'Organization',
			'@context': 'https://schema.org',
			name: company.value.name,
			url: getUrlWithHttps(domain.value),
			telephone: phoneService.getFormattedPhone(phone, 'BR') || undefined,
			email: company.value.email || undefined,
			address: company.value.address || undefined,
			sameAs: company.value.socialMediaList.map(socialMedia => socialMedia.url),
			logo: image ? logo : undefined
		};
	});

	return organizationJsonLd;
}
