<template>
	<section
		:class="['section-wrapper', { parallax: isParallaxEnabled }]"
		:data-variant="variant"
	>
		<div class="overlay">
			<PaddingContainer
				class="about"
				:class="placement"
				:padding="padding"
				component="div"
			>
				<div class="about-content">
					<h2 class="heading-font about-title">
						{{ title }}
					</h2>
					<div class="default-font about-text">
						<ParagraphsWithLineBreak :text="description" />
					</div>

					<div class="buttons-container">
						<ButtonBlock
							v-for="(button, index) in buttons"
							:key="`about-section-button:${index}:${button.config.text}`"
							v-bind="button.config"
						/>
					</div>
				</div>

				<div v-if="variant === 'simplified' && imageUrl" class="about-image">
					<NuxtImg
						:src="imageUrl"
						:alt="description"
						width="100%"
						:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
						loading="lazy"
					/>
				</div>
			</PaddingContainer>
		</div>
	</section>
</template>

<script setup lang="ts">
import type { AboutSection } from '@SHARED/core/entities/sections/AboutSection';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { FlexOrientation, TextAlign } from '@SHARED/utils/helperTypes';
import type { CSSColorVar } from '@SHARED/utils/style';
import type { FlexOption } from '@SHARED/composables/useFlexPosition';

import {
	getCSSColorVar,
	DEFAULT_NUXT_IMAGE_PLACEHOLDER
} from '@SHARED/utils/style';

import ParagraphsWithLineBreak from '@SHARED/components/atoms/ParagraphsWithLineBreak.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';

defineOptions({ name: 'AboutSection' });

const props = withDefaults(defineProps<AboutSection['config']>(), {
	imageUrl: null,
	variant: 'default',
	overlayOpacity: 0,
	isParallaxEnabled: true,
	contentPosition: 'left',
	buttonsOrientation: null,
	padding: null,
	textAlignment: 'left'
});

const styles = useState<WebsiteStyles>('styles');

const positionOptions = {
	center: 'items-center',
	left: 'items-left',
	right: 'items-right'
};

const overlay = computed<string>(() => {
	if (props.variant === 'default') {
		return props.overlayOpacity ? `rgba(0,0,0,${props.overlayOpacity})` : '';
	}

	return 'rgba(0,0,0,0)';
});

const placement = computed<string>(
	() => positionOptions[props.contentPosition]
);

const textColor = computed<string>(() =>
	getCSSColorVar(props?.textColor || styles.value.appearance.text)
);

const titleTextColor = computed<string>(() =>
	getCSSColorVar(
		props?.titleTextColor || props?.textColor || styles.value.appearance.text
	)
);

const backgroundColor = computed<CSSColorVar>(() =>
	getCSSColorVar(props?.backgroundColor || styles.value.appearance.background)
);

const contentPadding = computed<string>(() =>
	props.variant !== 'default' ? '5rem' : '10rem'
);

const $img = useImage();

const sectionBackground = computed(() => {
	if (!props.imageUrl?.trim()) return backgroundColor.value;

	if (props.variant === 'default') {
		// <!-- TODO: no futuro podemos usar o método $img.getSizes para otimizar mais a imagem, mas no momento a API desse método ainda é instável e pode mudar ou ser removida a qualquer momento -->
		const optimizedImageUrl = $img(props.imageUrl, {
			width: 1000,
			format: 'webp',
			quality: 80
		});

		return `url(${optimizedImageUrl})`;
	}

	return `linear-gradient(${getGradientOrientation()}, var(--white), var(--white) 30%, ${backgroundColor.value} 30%)`;
});

const mobileButtonsOrientation = computed<FlexOrientation>(
	() => props.buttonsOrientation?.mobile || 'row'
);

const desktopButtonsOrientation = computed<FlexOrientation>(
	() => props.buttonsOrientation?.desktop || 'row'
);

const buttonsAlignmentOptions: Record<TextAlign, FlexOption> = {
	center: 'center',
	left: 'flex-start',
	right: 'flex-end',
	justify: 'flex-start'
};

function getAlignment(
	configuredOrientation: FlexOrientation,
	expectedOrientation: FlexOrientation
): FlexOption {
	return configuredOrientation === expectedOrientation
		? buttonsAlignmentOptions[props.textAlignment]
		: 'flex-start';
}

const desktopButtonsAlignItems = computed<FlexOption>(() =>
	getAlignment(desktopButtonsOrientation.value, 'column')
);

const desktopButtonsJustifyContent = computed<FlexOption>(() =>
	getAlignment(desktopButtonsOrientation.value, 'row')
);

const mobileButtonsAlignItems = computed<FlexOption>(() =>
	getAlignment(mobileButtonsOrientation.value, 'column')
);

const mobileButtonsJustifyContent = computed<FlexOption>(() =>
	getAlignment(mobileButtonsOrientation.value, 'row')
);

function getGradientOrientation(): `to ${'right' | 'left'}` {
	if (props.contentPosition === 'left' || props.contentPosition === 'right') {
		return `to ${props.contentPosition}`;
	}

	return 'to right';
}
</script>

<style lang="scss" scoped>
.overlay {
	background-color: v-bind(overlay);
	width: 100%;
	height: 100%;
	display: flex;
}

.section-wrapper {
	width: 100%;
	background: v-bind(sectionBackground);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&[data-variant='simplified'] {
		background: v-bind(backgroundColor);

		@include screen-up(xl) {
			background: v-bind(sectionBackground);
		}
	}

	.about {
		padding-top: v-bind(contentPadding);
		padding-bottom: v-bind(contentPadding);
		align-items: center;
		display: flex;
		color: v-bind(textColor);
		overflow: hidden;
		position: relative;

		.about-content {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			width: 100%;

			@include screen-up(xl) {
				max-width: 50%;
			}
			.about-text {
				text-align: v-bind(textAlignment);
				font-size: 1.125rem;
				font-weight: 300;
			}
		}

		.about-image {
			height: 100%;
			overflow: hidden;
			width: 100%;
			margin-bottom: 2rem;
			max-height: 380px;

			@include screen-up(xl) {
				margin-bottom: 0;
				width: 45%;
				max-height: 100%;
			}

			img {
				object-fit: cover;
				object-position: top;
				width: 100%;
				height: 100%;
			}
		}

		.about-title {
			font-size: 3rem;
			text-align: v-bind(textAlignment);
			color: v-bind(titleTextColor);

			@include screen-up(md) {
				font-size: 3.75rem;
			}
		}

		.buttons-container {
			display: flex;
			flex-direction: v-bind(mobileButtonsOrientation);
			gap: 1rem;
			justify-content: v-bind(mobileButtonsJustifyContent);
			align-items: v-bind(mobileButtonsAlignItems);

			@include screen-up(sm) {
				flex-direction: v-bind(desktopButtonsOrientation);
				justify-content: v-bind(desktopButtonsJustifyContent);
				align-items: v-bind(desktopButtonsAlignItems);
			}
		}
	}

	.about.items-center,
	.about.items-left,
	.about.items-right {
		flex-direction: column;
		justify-content: center;
	}

	.about.items-center {
		@include screen-up(xl) {
			justify-content: center;
		}
	}

	.about.items-left {
		@include screen-up(xl) {
			justify-content: space-between;
			flex-direction: row;
		}
	}

	.about.items-right {
		@include screen-up(xl) {
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}
}
</style>
