import type { CountryPhoneData } from '@SHARED/core/ports/services/PhoneService';

export const countriesPhoneDataList: CountryPhoneData[] = [
	{
		name: 'Afghanistan',
		code: '+93',
		iso: 'AF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/af.svg',
		mask: '##-###-####'
	},
	{
		name: 'Aland Islands',
		code: '+358',
		iso: 'AX',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ax.svg',
		mask: '(###) ###-##-##'
	},
	{
		name: 'Albania',
		code: '+355',
		iso: 'AL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/al.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Algeria',
		code: '+213',
		iso: 'DZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/dz.svg',
		mask: '##-###-####'
	},
	{
		name: 'American Samoa',
		code: '+1',
		iso: 'AS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/as.svg',
		mask: '(684) ###-####'
	},
	{
		name: 'Andorra',
		code: '+376',
		iso: 'AD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ad.svg',
		mask: '###-###'
	},
	{
		name: 'Angola',
		code: '+244',
		iso: 'AO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ao.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Anguilla',
		code: '+1',
		iso: 'AI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ai.svg',
		mask: '(264) ###-####'
	},
	{
		name: 'Antarctica',
		code: '+672',
		iso: 'AQ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/aq.svg',
		mask: '1##-###'
	},
	{
		name: 'Antigua and Barbuda',
		code: '+1',
		iso: 'AG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ag.svg',
		mask: '(268) ###-####'
	},
	{
		name: 'Argentina',
		code: '+54',
		iso: 'AR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ar.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Armenia',
		code: '+374',
		iso: 'AM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/am.svg',
		mask: '##-###-###'
	},
	{
		name: 'Aruba',
		code: '+297',
		iso: 'AW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/aw.svg',
		mask: '###-####'
	},
	{
		name: 'Ascension Island',
		code: '+247',
		iso: 'AC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
		mask: '####'
	},
	{
		name: 'Australia',
		code: '+61',
		iso: 'AU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/au.svg',
		mask: '#-####-####'
	},
	{
		name: 'Austria',
		code: '+43',
		iso: 'AT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/at.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Azerbaijan',
		code: '+994',
		iso: 'AZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/az.svg',
		mask: '##-###-##-##'
	},
	{
		name: 'Bahamas',
		code: '+1',
		iso: 'BS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bs.svg',
		mask: '(242) ###-####'
	},
	{
		name: 'Bahrain',
		code: '+973',
		iso: 'BH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bh.svg',
		mask: '####-####'
	},
	{
		name: 'Bangladesh',
		code: '+880',
		iso: 'BD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bd.svg',
		mask: '1###-######'
	},
	{
		name: 'Barbados',
		code: '+1',
		iso: 'BB',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bb.svg',
		mask: '(246) ###-####'
	},
	{
		name: 'Belarus',
		code: '+375',
		iso: 'BY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/by.svg',
		mask: '(##) ###-##-##'
	},
	{
		name: 'Belgium',
		code: '+32',
		iso: 'BE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/be.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Belize',
		code: '+501',
		iso: 'BZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bz.svg',
		mask: '###-####'
	},
	{
		name: 'Benin',
		code: '+229',
		iso: 'BJ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bj.svg',
		mask: '##-##-####'
	},
	{
		name: 'Bermuda',
		code: '+1',
		iso: 'BM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bm.svg',
		mask: '(441) ###-####'
	},
	{
		name: 'Bhutan',
		code: '+975',
		iso: 'BT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bt.svg',
		mask: ['17-###-###', '77-###-###', '#-###-###']
	},
	{
		name: 'Bolivia',
		code: '+591',
		iso: 'BO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bo.svg',
		mask: '#-###-####'
	},
	{
		name: 'Bosnia and Herzegovina',
		code: '+387',
		iso: 'BA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ba.svg',
		mask: ['##-####', '##-#####']
	},
	{
		name: 'Botswana',
		code: '+267',
		iso: 'BW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bw.svg',
		mask: '##-###-###'
	},
	{
		name: 'Brasil',
		code: '+55',
		iso: 'BR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
		mask: ['(##) ####-####', '(##) #####-####']
	},
	{
		name: 'British Indian Ocean Territory',
		code: '+246',
		iso: 'IO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/io.svg',
		mask: '###-####'
	},
	{
		name: 'Brunei Darussalam',
		code: '+673',
		iso: 'BN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bn.svg',
		mask: '###-####'
	},
	{
		name: 'Bulgaria',
		code: '+359',
		iso: 'BG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bg.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Burkina Faso',
		code: '+226',
		iso: 'BF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bf.svg',
		mask: '##-##-####'
	},
	{
		name: 'Burundi',
		code: '+257',
		iso: 'BI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bi.svg',
		mask: '##-##-####'
	},
	{
		name: 'Cambodia',
		code: '+855',
		iso: 'KH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kh.svg',
		mask: '##-###-###'
	},
	{
		name: 'Cameroon',
		code: '+237',
		iso: 'CM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cm.svg',
		mask: '####-####'
	},
	{
		name: 'Canada',
		code: '+1',
		iso: 'CA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ca.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Cape Verde',
		code: '+238',
		iso: 'CV',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cv.svg',
		mask: '(###) ##-##'
	},
	{
		name: 'Cayman Islands',
		code: '+1',
		iso: 'KY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ky.svg',
		mask: '(345) ###-####'
	},
	{
		name: 'Central African Republic',
		code: '+236',
		iso: 'CF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cf.svg',
		mask: '##-##-####'
	},
	{
		name: 'Chad',
		code: '+235',
		iso: 'TD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/td.svg',
		mask: '##-##-##-##'
	},
	{
		name: 'Chile',
		code: '+56',
		iso: 'CL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
		mask: '#-####-####'
	},
	{
		name: 'China',
		code: '+86',
		iso: 'CN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cn.svg',
		mask: ['(###) ####-###', '(###) ####-####', '##-#####-#####']
	},
	{
		name: 'Christmas Island',
		code: '+61',
		iso: 'CX',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cx.svg',
		mask: '#-####-####'
	},
	{
		name: 'Cocos (Keeling)  Islands',
		code: '+61',
		iso: 'CC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cc.svg',
		mask: '#-####-####'
	},
	{
		name: 'Colombia',
		code: '+57',
		iso: 'CO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/co.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Comoros',
		code: '+269',
		iso: 'KM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/km.svg',
		mask: '##-#####'
	},
	{
		name: 'Congo',
		code: '+242',
		iso: 'CG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cg.svg',
		mask: '##-#####'
	},
	{
		name: 'Cook Islands',
		code: '+682',
		iso: 'CK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ck.svg',
		mask: '##-###'
	},
	{
		name: 'Costa Rica',
		code: '+506',
		iso: 'CR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cr.svg',
		mask: '####-####'
	},
	{
		name: 'Croatia',
		code: '+385',
		iso: 'HR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/hr.svg',
		mask: '##-###-###'
	},
	{
		name: 'Cuba',
		code: '+53',
		iso: 'CU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cu.svg',
		mask: '#-###-####'
	},
	{
		name: 'Cyprus',
		code: '+357',
		iso: 'CY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cy.svg',
		mask: '##-###-###'
	},
	{
		name: 'Czech Republic',
		code: '+420',
		iso: 'CZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cz.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Democratic Republic of the Congo',
		code: '+243',
		iso: 'CD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/cd.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Denmark',
		code: '+45',
		iso: 'DK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/dk.svg',
		mask: '##-##-##-##'
	},
	{
		name: 'Djibouti',
		code: '+253',
		iso: 'DJ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/dj.svg',
		mask: '##-##-##-##'
	},
	{
		name: 'Dominica',
		code: '+1',
		iso: 'DM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/dm.svg',
		mask: '(767) ###-####'
	},
	{
		name: 'Dominican Republic',
		code: '+1',
		iso: 'DO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/do.svg',
		mask: ['(809) ###-####', '(829) ###-####', '(849) ###-####']
	},
	{
		name: 'Ecuador',
		code: '+593',
		iso: 'EC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ec.svg',
		mask: ['#-###-####', '##-###-####']
	},
	{
		name: 'Egypt',
		code: '+20',
		iso: 'EG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/eg.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'El Salvador',
		code: '+503',
		iso: 'SV',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sv.svg',
		mask: '##-##-####'
	},
	{
		name: 'Equatorial Guinea',
		code: '+240',
		iso: 'GQ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gq.svg',
		mask: '##-###-####'
	},
	{
		name: 'Eritrea',
		code: '+291',
		iso: 'ER',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/er.svg',
		mask: '#-###-###'
	},
	{
		name: 'Estonia',
		code: '+372',
		iso: 'EE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ee.svg',
		mask: ['###-####', '####-####']
	},
	{
		name: 'Eswatini',
		code: '+268',
		iso: 'SZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sz.svg',
		mask: '##-##-####'
	},
	{
		name: 'Ethiopia',
		code: '+251',
		iso: 'ET',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/et.svg',
		mask: '##-###-####'
	},
	{
		name: 'Falkland Islands (Malvinas) ',
		code: '+500',
		iso: 'FK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/fk.svg',
		mask: '#####'
	},
	{
		name: 'Faroe Islands',
		code: '+298',
		iso: 'FO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/fo.svg',
		mask: '###-###'
	},
	{
		name: 'Fiji',
		code: '+679',
		iso: 'FJ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/fj.svg',
		mask: '##-#####'
	},
	{
		name: 'Finland',
		code: '+358',
		iso: 'FI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/fi.svg',
		mask: '(###) ###-##-##'
	},
	{
		name: 'France',
		code: '+33',
		iso: 'FR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/fr.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'French Guiana',
		code: '+594',
		iso: 'GF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gf.svg',
		mask: '#####-####'
	},
	{
		name: 'French Polynesia',
		code: '+689',
		iso: 'PF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pf.svg',
		mask: '##-##-##'
	},
	{
		name: 'Gabon',
		code: '+241',
		iso: 'GA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ga.svg',
		mask: '#-##-##-##'
	},
	{
		name: 'Gambia',
		code: '+220',
		iso: 'GM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gm.svg',
		mask: '(###) ##-##'
	},
	{
		name: 'Georgia',
		code: '+995',
		iso: 'GE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ge.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Germany',
		code: '+49',
		iso: 'DE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/de.svg',
		mask: [
			'###-###',
			'(###) ##-##',
			'(###) ##-###',
			'(###) ##-####',
			'(###) ###-####',
			'(####) ###-####'
		]
	},
	{
		name: 'Ghana',
		code: '+233',
		iso: 'GH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gh.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Gibraltar',
		code: '+350',
		iso: 'GI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gi.svg',
		mask: '###-#####'
	},
	{
		name: 'Greece',
		code: '+30',
		iso: 'GR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gr.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Greenland',
		code: '+299',
		iso: 'GL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gl.svg',
		mask: '##-##-##'
	},
	{
		name: 'Grenada',
		code: '+1',
		iso: 'GD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gd.svg',
		mask: '(473) ###-####'
	},
	{
		name: 'Guadeloupe',
		code: '+590',
		iso: 'GP',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gp.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Guam',
		code: '+1',
		iso: 'GU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gu.svg',
		mask: '(671) ###-####'
	},
	{
		name: 'Guatemala',
		code: '+502',
		iso: 'GT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gt.svg',
		mask: '#-###-####'
	},
	{
		name: 'Guernsey',
		code: '+44',
		iso: 'GG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gg.svg',
		mask: '(####) ######'
	},
	{
		name: 'Guinea',
		code: '+224',
		iso: 'GN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gn.svg',
		mask: '##-###-###'
	},
	{
		name: 'Guinea-Bissau',
		code: '+245',
		iso: 'GW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gw.svg',
		mask: '#-######'
	},
	{
		name: 'Guyana',
		code: '+592',
		iso: 'GY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gy.svg',
		mask: '###-####'
	},
	{
		name: 'Haiti',
		code: '+509',
		iso: 'HT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ht.svg',
		mask: '##-##-####'
	},
	{
		name: 'Holy See (Vatican City State) ',
		code: '+39',
		iso: 'VA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/va.svg',
		mask: '06 698#####'
	},
	{
		name: 'Honduras',
		code: '+504',
		iso: 'HN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/hn.svg',
		mask: '####-####'
	},
	{
		name: 'Hong Kong',
		code: '+852',
		iso: 'HK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/hk.svg',
		mask: '####-####'
	},
	{
		name: 'Hungary',
		code: '+36',
		iso: 'HU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/hu.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Iceland',
		code: '+354',
		iso: 'IS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/is.svg',
		mask: '###-####'
	},
	{
		name: 'India',
		code: '+91',
		iso: 'IN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/in.svg',
		mask: '(####) ###-###'
	},
	{
		name: 'Indonesia',
		code: '+62',
		iso: 'ID',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/id.svg',
		mask: [
			'##-###-##',
			'##-###-###',
			'##-###-####',
			'(8##) ###-###',
			'(8##) ###-##-###'
		]
	},
	{
		name: 'Iran',
		code: '+98',
		iso: 'IR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ir.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Iraq',
		code: '+924',
		iso: 'IQ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/iq.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Ireland',
		code: '+353',
		iso: 'IE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ie.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Isle of Man',
		code: '+44',
		iso: 'IM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/im.svg',
		mask: '(####) ######'
	},
	{
		name: 'Israel',
		code: '+972',
		iso: 'IL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/il.svg',
		mask: ['#-###-####', '5#-###-####']
	},
	{
		name: 'Italy',
		code: '+39',
		iso: 'IT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/it.svg',
		mask: '(###) ####-###'
	},
	{
		name: 'Ivory Coast / Cote d Ivoire',
		code: '+225',
		iso: 'CI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ci.svg',
		mask: '##-###-###'
	},
	{
		name: 'Jamaica',
		code: '+1',
		iso: 'JM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/jm.svg',
		mask: '(876) ###-####'
	},
	{
		name: 'Japan',
		code: '+81',
		iso: 'JP',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/jp.svg',
		mask: ['(###) ###-###', '##-####-####']
	},
	{
		name: 'Jersey',
		code: '+44',
		iso: 'JE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/je.svg',
		mask: '(####) ####-######'
	},
	{
		name: 'Jordan',
		code: '+962',
		iso: 'JO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/jo.svg',
		mask: '#-####-####'
	},
	{
		name: 'Kazakhstan',
		code: '+77',
		iso: 'KZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kz.svg',
		mask: ['(6##) ###-##-##', '(7##) ###-##-##']
	},
	{
		name: 'Kenya',
		code: '+254',
		iso: 'KE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ke.svg',
		mask: '###-######'
	},
	{
		name: 'Kiribati',
		code: '+686',
		iso: 'KI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ki.svg',
		mask: '##-###'
	},
	{
		name: 'Korea, Democratic Peoples Republic of Korea',
		code: '+850',
		iso: 'KP',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kp.svg',
		mask: [
			'###-###',
			'####-####',
			'##-###-###',
			'###-####-###',
			'191-###-####',
			'####-#############'
		]
	},
	{
		name: 'Korea, Republic of South Korea',
		code: '+82',
		iso: 'KR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kr.svg',
		mask: '##-###-####'
	},
	{
		name: 'Kosovo',
		code: '+383',
		iso: 'XK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/xk.svg',
		mask: ['##-###-###', '###-###-###']
	},
	{
		name: 'Kuwait',
		code: '+965',
		iso: 'KW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kw.svg',
		mask: '####-####'
	},
	{
		name: 'Kyrgyzstan',
		code: '+996',
		iso: 'KG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kg.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Laos',
		code: '+856',
		iso: 'LA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/la.svg',
		mask: ['##-###-###', '(20##) ###-###']
	},
	{
		name: 'Latvia',
		code: '+371',
		iso: 'LV',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lv.svg',
		mask: '##-###-###'
	},
	{
		name: 'Lebanon',
		code: '+961',
		iso: 'LB',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lb.svg',
		mask: ['#-###-###', '##-###-###']
	},
	{
		name: 'Lesotho',
		code: '+266',
		iso: 'LS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ls.svg',
		mask: '#-###-####'
	},
	{
		name: 'Liberia',
		code: '+231',
		iso: 'LR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lr.svg',
		mask: '##-###-###'
	},
	{
		name: 'Libya',
		code: '+218',
		iso: 'LY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ly.svg',
		mask: ['##-###-###', '21-###-####']
	},
	{
		name: 'Liechtenstein',
		code: '+423',
		iso: 'LI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/li.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Lithuania',
		code: '+370',
		iso: 'LT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lt.svg',
		mask: '(###) ##-###'
	},
	{
		name: 'Luxembourg',
		code: '+352',
		iso: 'LU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lu.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Macau',
		code: '+853',
		iso: 'MO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mo.svg',
		mask: '####-####'
	},
	{
		name: 'Madagascar',
		code: '+261',
		iso: 'MG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mg.svg',
		mask: '##-##-#####'
	},
	{
		name: 'Malawi',
		code: '+265',
		iso: 'MW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mw.svg',
		mask: ['1-###-###', '#-####-####']
	},
	{
		name: 'Malaysia',
		code: '+60',
		iso: 'MY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/my.svg',
		mask: ['#-###-###', '##-###-###', '(###) ###-###', '##-###-####']
	},
	{
		name: 'Maldives',
		code: '+960',
		iso: 'MV',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mv.svg',
		mask: '###-####'
	},
	{
		name: 'Mali',
		code: '+223',
		iso: 'ML',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ml.svg',
		mask: '##-##-####'
	},
	{
		name: 'Malta',
		code: '+356',
		iso: 'MT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mt.svg',
		mask: '####-####'
	},
	{
		name: 'Marshall Islands',
		code: '+692',
		iso: 'MH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mh.svg',
		mask: '###-####'
	},
	{
		name: 'Martinique',
		code: '+596',
		iso: 'MQ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mq.svg',
		mask: '(###) ##-##-##'
	},
	{
		name: 'Mauritania',
		code: '+222',
		iso: 'MR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mr.svg',
		mask: '##-##-####'
	},
	{
		name: 'Mauritius',
		code: '+230',
		iso: 'MU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mu.svg',
		mask: '###-####'
	},
	{
		name: 'Mayotte',
		code: '+262',
		iso: 'YT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/yt.svg',
		mask: '#####-####'
	},
	{
		name: 'Mexico',
		code: '+52',
		iso: 'MX',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mx.svg',
		mask: ['##-##-####', '(###) ###-####']
	},
	{
		name: 'Micronesia, Federated States of Micronesia',
		code: '+691',
		iso: 'FM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/fm.svg',
		mask: '###-####'
	},
	{
		name: 'Moldova',
		code: '+373',
		iso: 'MD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/md.svg',
		mask: '####-####'
	},
	{
		name: 'Monaco',
		code: '+377',
		iso: 'MC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mc.svg',
		mask: ['##-###-###', '(###) ###-###']
	},
	{
		name: 'Mongolia',
		code: '+976',
		iso: 'MN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mn.svg',
		mask: '##-##-####'
	},
	{
		name: 'Montenegro',
		code: '+382',
		iso: 'ME',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/me.svg',
		mask: '##-###-###'
	},
	{
		name: 'Montserrat',
		code: '+1',
		iso: 'MS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ms.svg',
		mask: '(664) ###-####'
	},
	{
		name: 'Morocco',
		code: '+212',
		iso: 'MA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ma.svg',
		mask: '##-####-###'
	},
	{
		name: 'Mozambique',
		code: '+258',
		iso: 'MZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mz.svg',
		mask: '##-###-###'
	},
	{
		name: 'Myanmar',
		code: '+95',
		iso: 'MM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mm.svg',
		mask: ['###-###', '#-###-###', '##-###-###']
	},
	{
		name: 'Namibia',
		code: '+224',
		iso: 'NA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/na.svg',
		mask: '##-###-####'
	},
	{
		name: 'Nauru',
		code: '+674',
		iso: 'NR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/nr.svg',
		mask: '###-####'
	},
	{
		name: 'Nepal',
		code: '+977',
		iso: 'NP',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/np.svg',
		mask: '##-###-###'
	},
	{
		name: 'Netherlands',
		code: '+31',
		iso: 'NL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/nl.svg',
		mask: '##-###-####'
	},
	{
		name: 'Netherlands Antilles',
		code: '+599',
		iso: 'AN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg',
		mask: ['###-####', '9###-####']
	},
	{
		name: 'New Caledonia',
		code: '+687',
		iso: 'NC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/nc.svg',
		mask: '##-####'
	},
	{
		name: 'New Zealand',
		code: '+24',
		iso: 'NZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/nz.svg',
		mask: ['#-###-###', '(###) ###-###', '(###) ###-####']
	},
	{
		name: 'Nicaragua',
		code: '+505',
		iso: 'NI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ni.svg',
		mask: '####-####'
	},
	{
		name: 'Niger',
		code: '+227',
		iso: 'NE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ne.svg',
		mask: '##-##-####'
	},
	{
		name: 'Nigeria',
		code: '+234',
		iso: 'NG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ng.svg',
		mask: ['##-###-##', '##-###-###', '(###) ###-####']
	},
	{
		name: 'Niue',
		code: '+683',
		iso: 'NU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/nu.svg',
		mask: '####'
	},
	{
		name: 'Norfolk Island',
		code: '+672',
		iso: 'NF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/nf.svg',
		mask: '3##-###'
	},
	{
		name: 'North Macedonia',
		code: '+389',
		iso: 'MK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mk.svg',
		mask: '##-###-###'
	},
	{
		name: 'Northern Mariana Islands',
		code: '+1',
		iso: 'MP',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mp.svg',
		mask: '(670) ###-####'
	},
	{
		name: 'Norway',
		code: '+47',
		iso: 'NO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/no.svg',
		mask: '(###) ##-###'
	},
	{
		name: 'Oman',
		code: '+968',
		iso: 'OM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/om.svg',
		mask: '##-###-###'
	},
	{
		name: 'Pakistan',
		code: '+92',
		iso: 'PK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pk.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Palau',
		code: '+680',
		iso: 'PW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pw.svg',
		mask: '###-####'
	},
	{
		name: 'Palestine',
		code: '+970',
		iso: 'PS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ps.svg',
		mask: '##-###-####'
	},
	{
		name: 'Panama',
		code: '+507',
		iso: 'PA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pa.svg',
		mask: '###-####'
	},
	{
		name: 'Papua New Guinea',
		code: '+675',
		iso: 'PG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pg.svg',
		mask: '(###) ##-###'
	},
	{
		name: 'Paraguay',
		code: '+595',
		iso: 'PY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Peru',
		code: '+51',
		iso: 'PE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pe.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Philippines',
		code: '+63',
		iso: 'PH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ph.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Pitcairn',
		code: '+870',
		iso: 'PN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pn.svg',
		mask: '###-###-###'
	},
	{
		name: 'Poland',
		code: '+48',
		iso: 'PL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pl.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Portugal',
		code: '+351',
		iso: 'PT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pt.svg',
		mask: '##-###-####'
	},
	{
		name: 'Puerto Rico',
		code: '+1',
		iso: 'PR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pr.svg',
		mask: ['(787)  ### ####', '(939)  ### ####']
	},
	{
		name: 'Qatar',
		code: '+974',
		iso: 'QA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/qa.svg',
		mask: '####-####'
	},
	{
		name: 'Reunion',
		code: '+262',
		iso: 'RE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/re.svg',
		mask: '#####-####'
	},
	{
		name: 'Romania',
		code: '+40',
		iso: 'RO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ro.svg',
		mask: '##-###-####'
	},
	{
		name: 'Russia',
		code: '+7',
		iso: 'RU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ru.svg',
		mask: '(###) ###-##-##'
	},
	{
		name: 'Rwanda',
		code: '+250',
		iso: 'RW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/rw.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Saint Barthelemy',
		code: '+590',
		iso: 'BL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/bl.svg',
		mask: '###-##-##-##'
	},
	{
		name: 'Saint Helena, Ascension and Tristan Da Cunha',
		code: '+290',
		iso: 'SH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
		mask: '####'
	},
	{
		name: 'Saint Kitts and Nevis',
		code: '+1',
		iso: 'KN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/kn.svg',
		mask: '(869) ###-####'
	},
	{
		name: 'Saint Lucia',
		code: '+1',
		iso: 'LC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lc.svg',
		mask: '(758) ###-####'
	},
	{
		name: 'Saint Martin',
		code: '+590',
		iso: 'MF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/mf.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Saint Pierre and Miquelon',
		code: '+508',
		iso: 'PM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/pm.svg',
		mask: '##-####'
	},
	{
		name: 'Saint Vincent and the Grenadines',
		code: '+1',
		iso: 'VC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/vc.svg',
		mask: '(784) ###-####'
	},
	{
		name: 'Samoa',
		code: '+685',
		iso: 'WS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ws.svg',
		mask: '##-####'
	},
	{
		name: 'San Marino',
		code: '+378',
		iso: 'SM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sm.svg',
		mask: '####-######'
	},
	{
		name: 'Sao Tome and Principe',
		code: '+239',
		iso: 'ST',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/st.svg',
		mask: '##-#####'
	},
	{
		name: 'Saudi Arabia',
		code: '+966',
		iso: 'SA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sa.svg',
		mask: ['#-###-####', '5#-####-####']
	},
	{
		name: 'Senegal',
		code: '+221',
		iso: 'SN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sn.svg',
		mask: '##-###-####'
	},
	{
		name: 'Serbia',
		code: '+381',
		iso: 'RS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/rs.svg',
		mask: '##-###-####'
	},
	{
		name: 'Seychelles',
		code: '+248',
		iso: 'SC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sc.svg',
		mask: '#-###-###'
	},
	{
		name: 'Sierra Leone',
		code: '+232',
		iso: 'SL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sl.svg',
		mask: '##-######'
	},
	{
		name: 'Singapore',
		code: '+65',
		iso: 'SG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sg.svg',
		mask: '####-####'
	},
	{
		name: 'Sint Maarten',
		code: '+1',
		iso: 'SX',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sx.svg',
		mask: '(721) ###-####'
	},
	{
		name: 'Slovakia',
		code: '+421',
		iso: 'SK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sk.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Slovenia',
		code: '+386',
		iso: 'SI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/si.svg',
		mask: '##-###-###'
	},
	{
		name: 'Solomon Islands',
		code: '+677',
		iso: 'SB',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sb.svg',
		mask: ['#####', '###-####']
	},
	{
		name: 'Somalia',
		code: '+252',
		iso: 'SO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/so.svg',
		mask: ['#-###-###', '##-###-###']
	},
	{
		name: 'South Africa',
		code: '+27',
		iso: 'ZA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/za.svg',
		mask: '##-###-####'
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		code: '+500',
		iso: 'GS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gs.svg',
		mask: '#####'
	},
	{
		name: 'South Sudan',
		code: '+211',
		iso: 'SS',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ss.svg',
		mask: '##-###-####'
	},
	{
		name: 'Spain',
		code: '+34',
		iso: 'ES',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/es.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Sri Lanka',
		code: '+94',
		iso: 'LK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/lk.svg',
		mask: '##-###-####'
	},
	{
		name: 'Sudan',
		code: '+249',
		iso: 'SD',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sd.svg',
		mask: '##-###-####'
	},
	{
		name: 'Suriname',
		code: '+597',
		iso: 'SR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sr.svg',
		mask: ['###-###', '###-####']
	},
	{
		name: 'Svalbard and Jan Mayen',
		code: '+47',
		iso: 'SJ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sj.svg',
		mask: '(###) ##-###'
	},
	{
		name: 'Sweden',
		code: '+46',
		iso: 'SE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/se.svg',
		mask: '##-###-####'
	},
	{
		name: 'Switzerland',
		code: '+41',
		iso: 'CH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ch.svg',
		mask: '##-###-####'
	},
	{
		name: 'Syrian Arab Republic',
		code: '+963',
		iso: 'SY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/sy.svg',
		mask: '##-####-###'
	},
	{
		name: 'Taiwan',
		code: '+886',
		iso: 'TW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tw.svg',
		mask: ['####-####', '#-####-####']
	},
	{
		name: 'Tajikistan',
		code: '+992',
		iso: 'TJ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tj.svg',
		mask: '##-###-####'
	},
	{
		name: 'Tanzania, United Republic of Tanzania',
		code: '+255',
		iso: 'TZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tz.svg',
		mask: '##-###-####'
	},
	{
		name: 'Thailand',
		code: '+66',
		iso: 'TH',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/th.svg',
		mask: ['##-###-###', '##-###-####']
	},
	{
		name: 'Timor-Leste',
		code: '+670',
		iso: 'TL',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tl.svg',
		mask: ['###-####', '77#-#####', '78#-#####']
	},
	{
		name: 'Togo',
		code: '+228',
		iso: 'TG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tg.svg',
		mask: '##-###-###'
	},
	{
		name: 'Tokelau',
		code: '+690',
		iso: 'TK',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tk.svg',
		mask: '####'
	},
	{
		name: 'Tonga',
		code: '+676',
		iso: 'TO',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/to.svg',
		mask: '#####'
	},
	{
		name: 'Trinidad and Tobago',
		code: '+1',
		iso: 'TT',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tt.svg',
		mask: '(868) ###-####'
	},
	{
		name: 'Tunisia',
		code: '+216',
		iso: 'TN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tn.svg',
		mask: '##-###-###'
	},
	{
		name: 'Turkey',
		code: '+90',
		iso: 'TR',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tr.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Turkmenistan',
		code: '+993',
		iso: 'TM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tm.svg',
		mask: '#-###-####'
	},
	{
		name: 'Turks and Caicos Islands',
		code: '+1',
		iso: 'TC',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tc.svg',
		mask: '(249) ###-###'
	},
	{
		name: 'Tuvalu',
		code: '+688',
		iso: 'TV',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/tv.svg',
		mask: ['2####', '90####']
	},
	{
		name: 'Uganda',
		code: '+256',
		iso: 'UG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ug.svg',
		mask: '(###) ###-###'
	},
	{
		name: 'Ukraine',
		code: '+380',
		iso: 'UA',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ua.svg',
		mask: '(##) ###-##-##'
	},
	{
		name: 'United Arab Emirates',
		code: '+971',
		iso: 'AE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ae.svg',
		mask: ['#-###-####', '5#-###-####']
	},
	{
		name: 'United Kingdom',
		code: '+44',
		iso: 'GB',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg',
		mask: '##-####-####'
	},
	{
		name: 'United States',
		code: '+1',
		iso: 'US',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Uruguay',
		code: '+598',
		iso: 'UY',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/uy.svg',
		mask: '#-###-##-##'
	},
	{
		name: 'Uzbekistan',
		code: '+998',
		iso: 'UZ',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/uz.svg',
		mask: '##-###-####'
	},
	{
		name: 'Vanuatu',
		code: '+678',
		iso: 'VU',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/vu.svg',
		mask: ['#####', '##-#####']
	},
	{
		name: 'Venezuela, Bolivarian Republic of Venezuela',
		code: '+58',
		iso: 'VE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ve.svg',
		mask: '(###) ###-####'
	},
	{
		name: 'Vietnam',
		code: '+84',
		iso: 'VN',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/vn.svg',
		mask: ['##-####-###', '(###) ####-###']
	},
	{
		name: 'Virgin Islands, British',
		code: '+1',
		iso: 'VG',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/vg.svg',
		mask: '(284) ###-####'
	},
	{
		name: 'Virgin Islands, U.S.',
		code: '+1',
		iso: 'VI',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/vi.svg',
		mask: '(340) ###-####'
	},
	{
		name: 'Wallis and Futuna',
		code: '+681',
		iso: 'WF',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/wf.svg',
		mask: '##-####'
	},
	{
		name: 'Yemen',
		code: '+967',
		iso: 'YE',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/ye.svg',
		mask: ['#-###-###', '##-###-###', '###-###-###']
	},
	{
		name: 'Zambia',
		code: '+260',
		iso: 'ZM',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/zm.svg',
		mask: '##-###-####'
	},
	{
		name: 'Zimbabwe',
		code: '+263',
		iso: 'ZW',
		flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/zw.svg',
		mask: '#-######'
	}
];

Object.freeze(countriesPhoneDataList);
