import * as Sentry from '@sentry/vue';
import { joinURL } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';

import { isEmpty } from '@SHARED/utils';

import { createOperationsGenerator } from '#image';

const S3_BUCKETS = [
	{
		name: 'blintz-properties-sandbox',
		url: 'https://blintz-properties-sandbox.s3.amazonaws.com/'
	},
	{
		name: 'blintz-properties-sandbox',
		url: 'https://blintz-properties-sandbox.s3.us-east-2.amazonaws.com/'
	},
	{
		name: 'pilar-company-assets',
		url: 'https://pilar-company-assets.s3.amazonaws.com/'
	},
	{
		name: 'pilar-company-assets',
		url: 'https://pilar-company-assets.s3.us-east-2.amazonaws.com/'
	}
];

const cloudfrontAvailableKeys = [
	'format',
	'quality',
	'blur',
	'width',
	'height'
];
const cloudfrontAvailableFormats = ['webp', 'png', 'jpg'];
// * width and height are not in this list because they are handled differently, but they are still number values
const numberValueKeys = ['quality', 'blur'];

const operationsGenerator = createOperationsGenerator();

// TODO: Investigar porque essa função funciona localmente, mas em produção não
// function normalizeNFDAndEncodeBase64(path: string) {
// 	const nfdStr = path.normalize('NFD');
// 	const utf8Bytes = new TextEncoder().encode(nfdStr);
// 	const base64String = btoa(String.fromCharCode(...utf8Bytes));

// 	return base64String;
// }

function validatePath(path: string) {
	const regex = /[\u00C0-\u00FF\s]/;
	return regex.test(path);
}

export const getImage: ProviderGetImage = (
	src,
	{ modifiers = {}, baseUrl } = {}
) => {
	try {
		const imageUrl = src.trim();

		if (imageUrl.endsWith('.svg')) {
			return { url: imageUrl };
		}

		const bucket = S3_BUCKETS.find(({ url }) => imageUrl.startsWith(url));

		if (!bucket) return { url: imageUrl };

		const imagePath = imageUrl.replace(bucket.url, '');
		const isInvalid = validatePath(imagePath);

		const operations = operationsGenerator(modifiers).split('/');

		const edits: Record<string, any> = {};

		if (isInvalid) {
			return { url: decodeURI(imageUrl.normalize('NFD')) };
		}

		for (const operation of operations) {
			const [key, value] = operation.split('=');

			if (!value) continue;

			if (!cloudfrontAvailableKeys.includes(key)) continue;

			if (key === 'width' || key === 'height') {
				edits.resize ||= {};
				edits.resize[key] = +value;
			} else if (key === 'format') {
				if (!cloudfrontAvailableFormats.includes(value)) continue;

				edits.format = value;
			} else {
				edits[key] = numberValueKeys.includes(key) ? +value : value;
			}
		}

		edits.format ||= 'webp';
		edits.rotate ||= null; // it avoids flipping image bug from lambda that aways happen on vertical aspect ratio images. (https://github.com/aws-solutions/serverless-image-handler/issues/540)

		if (!Object.keys(edits).length) {
			return { url: imageUrl };
		}

		const imageRequest = JSON.stringify({
			bucket: bucket.name,
			key: imagePath,
			edits
		});

		return { url: joinURL(baseUrl, btoa(imageRequest)) };
	} catch (error) {
		Sentry.captureException(error, {
			originalException: error,
			data: {
				message: 'Error while getting image url using cloudfront provider.',
				source: 'cloudfrontImageProvider.getImage',
				imageSource: src.trim(),
				imageBaseUrl: baseUrl,
				modifiers: isEmpty(modifiers) ? null : modifiers
			}
		});

		return { url: src };
	}
};
