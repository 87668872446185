import type { SystemEnumsRepository } from '@SHARED/core/ports/repositories/SystemEnumsRepository';

import { Result } from '@SHARED/core/helpers/result';

export const nuxtSystemEnumsRepository: SystemEnumsRepository = {
	async getSystemEnums() {
		try {
			const systemEnums = await $fetch('/api/enums');

			if (!systemEnums) {
				return Result.error({
					message: 'Error while fetching enums from API',
					source: 'nuxtSystemEnumsRepository.getSystemEnums',
					originalErrorObject: null
				});
			}

			return Result.ok(systemEnums);
		} catch (error) {
			return Result.error({
				message: 'Error while fetching enums from API',
				source: 'nuxtSystemEnumsRepository.getSystemEnums',
				originalErrorObject: error as Error
			});
		}
	}
};
