import type { InjectionKey } from 'vue';

import type { ColorService } from '@SHARED/core/ports/services/ColorService';
import type { PhoneService } from '@SHARED/core/ports/services/PhoneService';
import type { PropertiesRepository } from '@SHARED/core/ports/repositories/PropertiesRepository';

export const COLOR_SERVICE: InjectionKey<ColorService> = Symbol('colorService');
export const PHONE_SERVICE: InjectionKey<PhoneService> = Symbol('phoneService');

export const PROPERTIES_REPOSITORY: InjectionKey<PropertiesRepository> = Symbol(
	'propertiesRepository'
);
