function transformMaskToRegEx(mask: string) {
	return mask.replaceAll('#', '[0-9]').replace('(', '\\(').replace(')', '\\)');
}

function transformMasksToRegEx(masks: string | string[]) {
	return Array.isArray(masks)
		? masks.map(transformMaskToRegEx)
		: [transformMaskToRegEx(masks)];
}

export function verifyValueBasedOnRegExes(
	masks: string | string[],
	value: string
): boolean {
	const regExes = transformMasksToRegEx(masks);
	return regExes.some(regEx => value.match(regEx));
}
