import type {
	WebPage,
	ContactPage,
	AboutPage,
	Blog,
	WithContext
} from 'schema-dts';
import type {
	BlockableRouteNames,
	WebsitePageName,
	WebsitePageWithName
} from '@SHARED/core/entities/WebsiteConfig';
import { concatUrlPath } from '@SHARED/utils/url';

type UsePageJsonLdParams = {
	pageConfig: ComputedRef<WebsitePageWithName<WebsitePageName> | null>;
};

type JsonLdPageName = 'AboutPage' | 'ContactPage' | 'WebPage' | 'Blog';

type JsonLdPage = ContactPage | AboutPage | WebPage | Blog;

export function usePageJsonLd({ pageConfig }: UsePageJsonLdParams) {
	const route = useRoute();

	const domain = useState<string>('domain');

	const blockedRoutes = useState<BlockableRouteNames[]>('blockedRoutes');

	const isPageBlocked = computed<boolean>(() => {
		const pageName = pageConfig.value?.name || route.name;

		if (!pageName) return true;

		return blockedRoutes.value.includes(pageName as BlockableRouteNames);
	});

	function getPageType({
		name
	}: WebsitePageWithName<WebsitePageName>): JsonLdPageName {
		switch (name) {
			case 'WhoWeAre':
				return 'AboutPage';
			case 'ContactPage':
				return 'ContactPage';
			case 'BlogListing':
				return 'Blog';
			default:
				return 'WebPage';
		}
	}

	const webPageJsonLd = computed<WithContext<JsonLdPage> | null>(() => {
		if (!pageConfig.value || isPageBlocked.value) return null;

		const type = getPageType(pageConfig.value);

		const url = concatUrlPath(domain.value, route.path);

		return {
			'@context': 'https://schema.org',
			'@type': type,
			name: pageConfig.value.meta?.title || undefined,
			about: pageConfig.value.meta?.description || undefined,
			description: pageConfig.value.meta?.description || undefined,
			image: pageConfig.value.meta?.image || undefined,
			url
		};
	});

	useJsonld(() => webPageJsonLd.value);
}
