<template>
	<section class="error-section-wrapper container">
		<span v-if="statusCode" class="heading-font error-code">
			{{ statusCode }}
		</span>
		<div class="error-information-container">
			<span class="heading-font error-title">{{ message }}</span>
			<span class="default-font error-description">{{ subtitle }}</span>
		</div>
		<div class="buttons-wrapper">
			<ButtonBlock
				v-if="isPropertiesListingPageEnabled"
				text="Ir para Imóveis"
				is-outlined
				:url="{ name: 'PropertiesListing' }"
				class="redirection-button"
			/>
			<ButtonBlock
				text="Ir para a Home"
				:is-outlined="false"
				:url="{ name: 'Home' }"
				class="redirection-button"
			/>
		</div>
	</section>
</template>

<script setup lang="ts">
import type {
	BlockableRouteNames,
	WebsiteConfig
} from '@SHARED/core/entities/WebsiteConfig';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';

type ErrorSectionProps = {
	statusCode?: number | null;
	message: string;
	subtitle: string;
};

defineOptions({ name: 'ErrorSection' });

withDefaults(defineProps<ErrorSectionProps>(), {
	statusCode: null
});

const nuxtAppData = useNuxtApp();

const websiteConfig = useState<WebsiteConfig>('websiteConfig');

const domain = useState<string>('domain');

if (process.server) {
	domain.value = nuxtAppData.ssrContext!.event.node.req.headers.host!;

	websiteConfig.value = await $fetch(`/api/websites/${domain.value}`, {
		headers: { host: domain.value }
	});
}

const blockedRoutes = useState<BlockableRouteNames[]>('blockedRoutes');

const isPropertiesListingPageEnabled = computed<boolean>(
	() => !blockedRoutes.value.includes('PropertiesListing')
);
</script>

<style lang="postcss" scoped>
.error-section-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: var(
		--error-section-wrapper-height,
		calc(100vh - var(--header-height))
	);
	background-color: var(--white);
	background-size: cover;
	background-position: center;
	gap: 2rem;

	.error-code {
		font-size: 7.5rem;
		line-height: 110%;

		@include screen-up(lg) {
			font-size: 12.5rem;
		}
	}

	.buttons-wrapper {
		display: flex;
		gap: 1rem;

		.redirection-button {
			font-weight: 500;
		}
	}

	.error-title,
	.error-description,
	.error-code {
		text-align: center;
	}

	.error-information-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.5rem;

		.error-title {
			font-size: 1.75rem;

			@include screen-up(lg) {
				font-size: 2.75rem;
			}
		}

		.error-description {
			font-size: 1.25rem;

			@include screen-up(lg) {
				font-size: 1.5rem;
			}
		}
	}
}
</style>
