<template>
	<div>
		<Teleport
			v-if="openPrivacyPolicyAsAModal && privacyPolicyModal"
			to="#teleports"
		>
			<ModalContainer
				v-if="isPrivacyPolicyModalOpen"
				@close-modal="closePrivacyPolicyModal"
			>
				<ModalContent
					:title="privacyPolicyModal.title"
					class="privacy-policy-modal-content-wrapper"
					has-scrollable-body
					@close-modal="closePrivacyPolicyModal"
				>
					<template #body>
						<div
							class="privacy-policy-modal-content-body"
							v-html="privacyPolicyModal.content"
						></div>
					</template>
				</ModalContent>
			</ModalContainer>
		</Teleport>

		<AboutSection v-if="aboutSection" v-bind="aboutSection.config" />

		<ContactSection
			v-if="contactSection"
			v-bind="contactSection.config"
			:company-information-icons-color="iconsColor"
		/>

		<footer class="footer-wrapper default-font">
			<PaddingContainer
				:padding="containerPadding"
				component="div"
				class="footer default-font"
			>
				<div v-if="isDefaultVariant" class="default-variant">
					<h2 class="footer-heading heading-font">
						{{ company.name }}
					</h2>
					<div
						class="company-information-items"
						:data-number-of-items="contactItems.length"
					>
						<CompanyInformationItem
							v-for="(link, index) in contactItems"
							:key="`footer-company-information-item-${index}`"
							:label="link.label"
							:content="link.content"
							:icon="link.icon"
							:icon-color="iconsColor"
							:url="link.url"
							class="information-item"
						/>
					</div>
				</div>
				<div v-if="isKollerVariant && logo" class="footer-logo-container">
					<NuxtImg :alt="company.name" :src="logo.imageUrl" />
				</div>
				<div
					v-if="!isSimplifiedVariant && !isMinisiteVariant"
					class="footer-divide"
				></div>
				<div class="footer-links">
					<p class="footer-link-content footer-content-gap">
						<PilarLogo />
						Desenvolvido por
						<NuxtLink
							:to="pilarCompanyUrl"
							target="_blank"
							class="footer-link fullsize-underline"
							rel="external"
						>
							Pilar
						</NuxtLink>
					</p>
					<p
						class="footer-link-content footer-content-gap footer-content-divide footer-wrap-content"
					>
						<span> Copyright © 2024 </span>
						<span v-if="company.creci && !isKollerVariant">
							Creci: {{ company.creci }}
						</span>
						<NuxtLink
							v-if="contactUrl && isKollerVariant"
							:to="contactUrl"
							class="footer-link"
						>
							Contato
						</NuxtLink>

						<Component :is="privacyPolicyComponent" class="footer-link" />

						<NuxtLink
							v-if="!isKollerVariant"
							:to="sitemapUrl"
							class="footer-link"
						>
							Mapa do Site
						</NuxtLink>
					</p>
					<div
						v-if="company.socialMediaList?.length"
						class="social-icons-wrapper"
					>
						<SocialIcon
							v-for="socialMedia in company.socialMediaList"
							:key="socialMedia.name"
							:social-media="socialMedia"
							target="_blank"
						/>
					</div>
				</div>
			</PaddingContainer>
		</footer>
	</div>
</template>

<script setup lang="tsx">
import markdownit from 'markdown-it';

import type { Company } from '@SHARED/core/entities/Company';
import type {
	Color,
	WebsiteStyles,
	BlockableRouteNames
} from '@SHARED/core/entities/WebsiteConfig';
import type { FooterSection } from '@SHARED/core/entities/sections/FooterSection';
import type {
	CssSize,
	ResponsiveSectionPadding
} from '@SHARED/utils/helperTypes';
import type { CompanyInformationItem as ICompanyInformationItem } from '@SHARED/presenters/CompanyInformationPresenter';
import type { CSSColorVar } from '@SHARED/utils/style';

import { getUrlForExternalContext } from '@SHARED/utils/url';
import { getCSSColorVar } from '@SHARED/utils/style';
import { usePilarWebsiteUtm } from '@SHARED/composables/usePilarWebsiteUtm';
import { useContactConfig } from '@SHARED/composables/useContactConfig';
import { CompanyInformationPresenter } from '@SHARED/presenters/CompanyInformationPresenter';

import { COLOR_SERVICE } from '@SHARED/utils/vueProvidersSymbols';

import AboutSection from '@SHARED/components/sections/AboutSection.vue';
import ContactSection from '@SHARED/components/sections/ContactSection.vue';
import SocialIcon from '@SHARED/components/atoms/SocialIcon.vue';
import CompanyInformationItem from '@SHARED/components/atoms/CompanyInformationItem.vue';
import PilarLogo from '@SHARED/components/atoms/svg/PilarLogo.vue';
import ModalContainer from '@SHARED/components/molecules/ModalContainer.vue';
import ModalContent from '@SHARED/components/molecules/ModalContent.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import { NuxtLink } from '#components';

defineOptions({ name: 'FooterSection' });

const colorService = inject(COLOR_SERVICE)!;

const styles = useState<WebsiteStyles>('styles');

const company = useState<Company>('company');

type FooterSectionProps = FooterSection['config'] & {
	isMinisiteContext?: boolean;
};

const props = withDefaults(defineProps<FooterSectionProps>(), {
	variant: 'default',
	isMinisiteContext: false,
	iconsColor: null,
	openPrivacyPolicyAsAModal: false,
	privacyPolicyModal: null
});

const isSimplifiedVariant = computed<boolean>(
	() => props.variant === 'simplified'
);

const isDefaultVariant = computed<boolean>(() => props.variant === 'default');

const isKollerVariant = computed<boolean>(() => props.variant === 'koller');

const isMinisiteVariant = computed<boolean>(() => props.variant === 'minisite');

const utmContext = isMinisiteVariant.value ? 'minisite' : 'website';

const { utmParams } = usePilarWebsiteUtm({ context: utmContext });

const pilarCompanyUrl = computed<string>(
	() => `https://www.soupilar.com.br?${utmParams.value}`
);

const footerBackgroundColor = computed<CSSColorVar>(() =>
	getCSSColorVar(props.backgroundColor || styles.value.appearance.background)
);

const footerTextColor = computed<Color>(
	() => props.textColor || styles.value.appearance.text
);

const footerTextColorVar = computed<CSSColorVar>(() =>
	getCSSColorVar(footerTextColor.value)
);

const cssIconsColorVar = computed<CSSColorVar>(() =>
	props.iconsColor ? getCSSColorVar(props.iconsColor) : footerTextColorVar.value
);

const containerPadding = computed<ResponsiveSectionPadding | null | undefined>(
	() => {
		if (isKollerVariant.value) {
			return props.padding || { desktop: '1rem 3.75rem', mobile: '1rem' };
		}

		return props.padding;
	}
);

const footerPaddingTop = computed<CssSize>(() =>
	!isSimplifiedVariant.value ? '2rem' : '0'
);

const footerVariantPadding = computed<CssSize>(() =>
	isKollerVariant.value || isMinisiteVariant.value ? '0' : '4rem'
);

const footerPaddingBottom = computed<CssSize>(() =>
	isKollerVariant.value || isMinisiteVariant.value ? '2rem' : '4rem'
);

const contactConfig = useContactConfig();

const contactItems = computed<ICompanyInformationItem[]>(() =>
	CompanyInformationPresenter.getInformationItems(
		contactConfig.value,
		company.value
	)
);

const footerDividerColor = computed<string>(() =>
	colorService.transparentizeColor(
		styles.value.colorPalette[footerTextColor.value],
		0.8
	)
);

const sitemapUrl = computed<string>(() =>
	getUrlForExternalContext(
		props.isMinisiteContext,
		'/mapa-do-site',
		company.value.website
	)
);

const privacyPolicyUrl = computed<string>(() =>
	getUrlForExternalContext(
		props.isMinisiteContext,
		'/politica-de-privacidade',
		company.value.website
	)
);

const blockedRoutes = useState<BlockableRouteNames[]>('blockedRoutes');

const contactUrl = computed<string | null>(() => {
	const isContactPageDisabled = blockedRoutes.value.includes('ContactPage');

	const isContactSectionDisabled = !props.contactSection;

	if (!isContactPageDisabled && !isContactSectionDisabled) return null;

	const path = isContactPageDisabled ? '/contato' : '#contato';

	return getUrlForExternalContext(
		props.isMinisiteContext,
		path,
		company.value.website
	);
});

const isPrivacyPolicyModalOpen = ref(false);

function openPrivacyPolicyModal() {
	isPrivacyPolicyModalOpen.value = true;
}

function closePrivacyPolicyModal() {
	isPrivacyPolicyModalOpen.value = false;
}

const privacyPolicyComponent = computed<VNode>(() => {
	if (props.openPrivacyPolicyAsAModal) {
		return (
			<button onClick={openPrivacyPolicyModal}>Política de Privacidade</button>
		);
	}

	return (
		<NuxtLink to={privacyPolicyUrl.value}>Política de Privacidade</NuxtLink>
	);
});

const md = markdownit();

const privacyPolicyModal = computed<{ title: string; content: string } | null>(
	() => {
		if (!isPrivacyPolicyModalOpen.value) return null;

		const title = props.privacyPolicyModal?.title || 'Política de Privacidade';
		const content = props.privacyPolicyModal?.content || '';

		const parsedMarkdown = content ? md.render(content) : '';

		return {
			title,
			content: parsedMarkdown
		};
	}
);
</script>

<style lang="scss" scoped>
$itemGap: 0.75rem;

.footer-divide {
	border-top: 1px solid v-bind(footerDividerColor);
	margin-top: 2rem;
}

.footer-link {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: calc(100% - $itemGap);
		border-bottom: 1px solid v-bind(footerTextColorVar);
	}
}

.footer-wrapper {
	background-color: v-bind(footerBackgroundColor);
	color: v-bind(footerTextColorVar);
	width: 100%;
	padding-top: v-bind(footerPaddingTop);
	padding-bottom: v-bind(footerPaddingBottom);

	.footer {
		flex-direction: column;
		display: flex;
		padding-top: v-bind(footerVariantPadding);

		.footer-heading {
			text-transform: uppercase;
			margin-bottom: 2rem;
			font-size: 1.875rem;
		}

		.footer-logo-container {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}
	}

	.default-variant {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include screen-up(md) {
			justify-content: start;
			align-items: start;
		}
	}

	.social-icons-wrapper {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 1rem 0;
		color: v-bind(cssIconsColorVar);
	}

	.company-information-items {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		@include screen-up(lg) {
			display: grid;
			width: 100%;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			justify-items: start;
		}

		@include screen-up(xl) {
			display: grid;
			width: 100%;

			&[data-number-of-items='3'] {
				grid-template-columns: repeat(3, minmax(0, 1fr));
				justify-items: center;
			}
		}

		@include screen-up(3xl) {
			&[data-number-of-items='4'] {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				gap: 1rem;
			}
		}

		.information-item {
			font-size: 0.875rem;
			line-height: 1.25rem;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			display: flex;

			@include screen-up(xl) {
				&:not(:first-child),
				&:not(:last-child) {
					justify-content: center;
				}

				&:last-child {
					justify-content: flex-end;
				}

				&:first-child {
					justify-content: flex-start;
				}
			}
		}
	}

	.footer-links {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 2rem;
		font-size: 1.125rem;
		gap: 1rem;

		@include screen-up(md) {
			display: grid;
			grid-template-columns: repeat(2, auto);
		}

		@include screen-up(lg) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.footer-link-content {
			text-align: center;

			&:first-child {
				margin-bottom: 1.5rem;

				@include screen-up(md) {
					margin-bottom: 0rem;
				}
			}
		}

		.footer-content-divide {
			display: flex;

			& > :not(:last-child) {
				border-right: 1px solid v-bind(footerDividerColor);
				padding-right: $itemGap;
				margin-right: 0rem;
			}

			& > :last-child {
				border-right: none;
				padding-right: 0;
				margin-right: 0;

				&::after {
					width: 100%;
				}
			}
		}

		.footer-content-gap {
			display: flex;
			gap: $itemGap;
		}

		.footer-wrap-content {
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}

		.fullsize-underline::after {
			width: 100%;
		}
	}
}

// <!-- * Estilização dos elementos do ModalContainer  -->
.modal-container :deep(.modal-content-wrapper) {
	height: 90vh;

	@supports (height: 90dvh) {
		height: 90dvh;
	}
}

.privacy-policy-modal-content-wrapper {
	@include screen-down(md) {
		:deep(.modal-title) {
			font-size: 1.25rem;
		}
	}
	:deep(.privacy-policy-modal-content-body) p:not(:last-child) {
		margin-bottom: 1rem;
	}
}
</style>
