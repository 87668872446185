import type { Organization } from 'schema-dts';
import type { Company } from '@SHARED/core/entities/Company';

import { getUrlWithHttps } from '@SHARED/utils/url';

import { useOrganizationJsonLd } from '@/composables/json-ld/useOrganizationJsonLd';

export function useWebsiteJsonLd() {
	const domain = useState<string>('domain');
	const company = useState<Company>('company');

	const pilarOrganization: Organization = {
		'@type': 'Organization',
		name: 'Pilar',
		url: 'https://www.soupilar.com.br/',
		sameAs: [
			'https://www.instagram.com/sou_pilar',
			'https://facebook.com/soupilarbr',
			'https://www.linkedin.com/company/soupilar',
			'https://www.youtube.com/@poweredbypilar',
			'https://open.spotify.com/show/1RIfOWEWWKRY1gXOgymgup'
		]
	};

	const organizationJsonLd = useOrganizationJsonLd();

	useJsonld(() => organizationJsonLd.value);

	useJsonld(() => ({
		'@context': 'https://schema.org',
		'@type': 'WebSite',
		name: company.value.name,
		url: getUrlWithHttps(domain.value),
		creator: pilarOrganization
	}));
}
