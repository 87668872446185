import type { Broker } from '@SHARED/core/entities/Broker';

import { SocialMediaModule } from '@SHARED/core/entities/SocialMedia/module';

export class PagesModule {
	static getWhoWeArePageValidBrokers(
		brokers: Omit<Broker, 'id' | 'companyId'>[]
	) {
		return brokers.map(broker => ({
			...broker,
			socialMediaList: SocialMediaModule.getValidAndUniqueSocialMediaList(
				broker.socialMediaList || []
			)
		}));
	}
}
