import {
	getLuminance,
	parseToRgb,
	shade,
	tint,
	transparentize
} from 'polished';

import type { ColorService } from '@SHARED/core/ports/services/ColorService';

export const polishedColorService: ColorService = {
	getHoverHexColor(color) {
		return getLuminance(color) > 0.4 ? shade(0.2, color) : tint(0.2, color);
	},
	transparentizeColor(color: string, amount: number): string {
		return transparentize(amount, color);
	},
	getRgbColor(color) {
		return parseToRgb(color);
	},
	getTextColorForBackground(backgroundColor) {
		return getLuminance(backgroundColor) > 0.4 ? 'black' : 'white';
	}
};
