
import * as cloudfrontRuntime$TuBpfbi3Fh from '/builds/blintz/front-web/hadid/hadid/providers/cloudfrontImage.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudfront",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudfront']: { provider: cloudfrontRuntime$TuBpfbi3Fh, defaults: {"baseUrl":"https://d1988evaubdc7a.cloudfront.net"} }
}
        