<template>
	<div data-page>
		<HeaderSection
			v-bind="header.config"
			:transparent-on-top-of-page="isTransparentHeader"
		/>
		<NuxtPage :class="pagePaddingClass" />
		<FooterSection v-bind="footerProps" />
		<ClientOnly>
			<CookieConsentModal />
		</ClientOnly>

		<FloatingAction v-if="floatingAction" v-bind="floatingAction" />

		<div id="teleports"></div>
	</div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core';

import type {
	WebsitePage,
	WebsiteConfig
} from '@SHARED/core/entities/WebsiteConfig';
import type { FooterSection as IFooterSection } from '@SHARED/core/entities/sections/FooterSection';
import type { FloatingAction as IFloatingAction } from '@SHARED/core/entities/components/FloatingAction';
import type { CssSize } from '@SHARED/utils/helperTypes';
import { getCssNumberValueInPixels } from '@SHARED/utils/style';

import { useAnimation } from '@SHARED/composables/useAnimation';

import HeaderSection from '@SHARED/components/sections/HeaderSection.vue';
import FooterSection from '@SHARED/components/sections/FooterSection.vue';

import {
	PROPERTIES_REPOSITORY,
	COLOR_SERVICE,
	PHONE_SERVICE
} from '@SHARED/utils/vueProvidersSymbols';
import { polishedColorService } from '@SHARED/adapters/services/color/polishedColorServiceAdapter';
import { phoneService } from '@SHARED/adapters/services/phone/phoneServiceAdapter';
import { nuxtSystemEnumsRepository } from '@/adapters/repositories/nuxt/nuxtSystemEnumsRepository';
import { nuxtPropertiesRepository } from '@/adapters/repositories/nuxt/nuxtPropertiesRepository';
import { nuxtCondosRepository } from '@/adapters/repositories/nuxt/nuxtCondosRepository';
import { nuxtBlogPostsRepository } from '@/adapters/repositories/nuxt/nuxtBlogPostsRepository';
import { useWebsiteJsonLd } from '@/composables/json-ld/useWebsiteJsonLd';
import { usePageJsonLd } from '@/composables/json-ld/pages/usePageJsonLd';
import CookieConsentModal from '@/components/organisms/CookieConsentModal.vue';
import FloatingAction from '@/components/molecules/FloatingAction.vue';

provide(SYSTEM_ENUMS_REPOSITORY, nuxtSystemEnumsRepository);
provide(PROPERTIES_REPOSITORY, nuxtPropertiesRepository);
provide(CONDOS_REPOSITORY, nuxtCondosRepository);
provide(BLOG_POSTS_REPOSITORY, nuxtBlogPostsRepository);
provide(COLOR_SERVICE, polishedColorService);
provide(PHONE_SERVICE, phoneService);

const route = useRoute();

const domain = useState<string>('domain');

const websiteConfig = useState<WebsiteConfig>('websiteConfig');

const pages = useState<WebsitePage[]>('pages');

const pageConfig = computed<WebsitePage | null>(
	() =>
		pages.value?.find((page: WebsitePage) => page.name === route.name) || null
);

const header = computed(() => websiteConfig.value.header);

const footer = computed(() => websiteConfig.value.footer);

const floatingAction = computed<IFloatingAction | null>(
	() => websiteConfig.value.floatingAction
);

const headerHeight = computed(() => {
	if (!header.value) return 0;

	return header.value.config.height;
});

const footerProps = computed<IFooterSection['config']>(() => {
	const footerConfig = footer.value.config;

	if (!pageConfig.value) return footerConfig;

	const { showFooterAboutSection, showFooterContactSection } = pageConfig.value;

	return {
		...footerConfig,
		aboutSection: showFooterAboutSection ? footerConfig.aboutSection : null,
		contactSection: showFooterContactSection
			? footerConfig.contactSection
			: null
	};
});

const isTransparentHeader = computed<boolean>(() => {
	if (!pageConfig.value || pageConfig.value.isHeaderTransparent === false) {
		return false;
	}

	return header.value.config.transparentOnTopOfPage || false;
});

const { y: yPosition } = useWindowScroll();

const { getAnimationClass } = useAnimation({
	animationStatus: 'start',
	animationStyle: 'page-padding'
});

const pagePaddingClass = computed<string>(() => {
	if (isTransparentHeader.value) return '';

	const headerHeightValue = getCssNumberValueInPixels(
		headerHeight.value || '0px'
	);

	const animationStatus = yPosition.value < headerHeightValue ? 'start' : 'end';

	return getAnimationClass(animationStatus);
});

useState('header', () => header);
useState('footer', () => footer);
useState('domain', () => domain);

const {
	primary: primaryColor,
	secondary: secondaryColor,
	accent: accentColor,
	black: blackColor,
	white: whiteColor,
	offwhite: offwhiteColor,
	success: successColor,
	danger: dangerColor,
	lightgray: lightgrayColor,
	gray: grayColor,
	darkgray: darkgrayColor
} = websiteConfig.value.styles.colorPalette;

const { fontFamily: defaultFontFamily } =
	websiteConfig.value.styles.typography.default;

const { fontFamily: headingFontFamily } =
	websiteConfig.value.styles.typography.heading;

const sitePaddingMobile = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.mobile || '5%'
);

const sitePaddingTablet = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.tablet || '7.5%'
);

const sitePaddingDesktop = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.desktop || '10%'
);

useWebsiteMetadata();
useWebsiteJsonLd();
usePageJsonLd({ pageConfig });
</script>

<style lang="postcss">
[data-page] {
	--sm-site-padding: v-bind(sitePaddingMobile);
	--md-site-padding: v-bind(sitePaddingTablet);
	--lg-site-padding: v-bind(sitePaddingDesktop);

	--header-height: v-bind(headerHeight);

	/* <!-- TODO: renomear para adicionar o sufixo `-color` --> */
	--primary: v-bind(primaryColor);
	--secondary: v-bind(secondaryColor);
	--accent: v-bind(accentColor);

	--black: v-bind(blackColor);
	--white: v-bind(whiteColor);
	--offwhite: v-bind(offwhiteColor);
	--danger: v-bind(dangerColor);
	--success: v-bind(successColor);
	--lightgray: v-bind(lightgrayColor);
	--gray: v-bind(grayColor);
	--darkgray: v-bind(darkgrayColor);

	--default-font: v-bind(defaultFontFamily), Ubuntu, system-ui, sans-serif;
	--heading-font: v-bind(headingFontFamily), TimesNewRoman, 'Times New Roman',
		Times, Baskerville, Georgia, serif;

	.heading-font {
		font-family: var(--heading-font);
	}

	.default-font {
		font-family: var(--default-font);
	}

	.page-padding {
		transition: all 0.2s ease-in-out;
		padding-top: 0;
	}

	.page-padding.active {
		/* <!-- TODO: não seria melhor usar essa variável dentro dos componentes que precisam dela ao invés de usar a classe? --> */
		padding-top: var(--header-height);
	}
}
</style>
