import type { BlogPostsRepository } from '@SHARED/core/ports/repositories/BlogPostsRepository';
import { Result } from '@SHARED/core/helpers/result';

export const nuxtBlogPostsRepository: BlogPostsRepository = {
	async getCompanyBlogPosts({ domain, maxQuantity, offset }) {
		try {
			const query = {
				offset,
				maxQuantity
			};

			const blogPosts = await $fetch(`/api/websites/${domain}/blog/posts`, {
				query
			});

			return Result.ok(
				blogPosts || {
					blogPosts: [],
					filteredCount: 0,
					totalCount: 0
				}
			);
		} catch (error) {
			return Result.error({
				message: `Error while fetching blog posts for domain ${domain}`,
				source: 'nuxtBlogPostsRepository.getCompanyBlogPosts',
				originalErrorObject: error as Error
			});
		}
	},
	async getBlogPostBySlug({ domain, slug }) {
		try {
			const blogPost = await $fetch(
				`/api/websites/${domain}/blog/posts/${slug}`
			);

			return Result.ok(blogPost || null);
		} catch (error) {
			if ((error as any)?.response?.status === 404) return Result.ok(null);

			return Result.error({
				message: `Error while fetching blog post with slug ${slug}`,
				source: 'nuxtBlogPostsRepository.getBlogPostBySlug',
				originalErrorObject: error as Error
			});
		}
	}
};
