import { Mask } from 'maska';

import type {
	PhoneService,
	CountryPhoneData
} from '@SHARED/core/ports/services/PhoneService';

import { countriesPhoneDataList } from '@SHARED/utils/phone/countries';

const defaultPhoneData: CountryPhoneData = {
	name: 'Brasil',
	code: '+55',
	iso: 'BR',
	flagUrl: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
	mask: ['(##) ####-####', '(##) #####-####']
};

export const phoneService: PhoneService = {
	getPhoneDataByField(field: 'name' | 'code' | 'iso', value: string) {
		const phoneData = countriesPhoneDataList.find(
			country => country[field] === value
		);

		if (!phoneData) return defaultPhoneData;

		return phoneData;
	},
	getFormattedPhone(value: string, iso: string) {
		const { mask } = this.getPhoneDataByField('iso', iso);

		const maska = new Mask({ mask });

		return maska.masked(value || '');
	},
	getPhoneValue(value: string) {
		return value.replace(/\D/g, '');
	}
};
