import revive_payload_client_iLTtgTktL1 from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_t68alr641N from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4kKh0Mukew from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4sBnIbRdCM from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Vh1dL4yNcG from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JQOvDqJY2C from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5LM65pWYdC from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/blintz/front-web/hadid/hadid/.nuxt/components.plugin.mjs";
import prefetch_client_Ai13c6FYUk from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.1_eslint@8.57.0_ioredis@5.4.1_magicast@0._obrkrx564a5t2qj5u3scv6uxxa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_giO07cn1Lr from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.4_rollup@4.21.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_gkFrgOgGJt from "/builds/blintz/front-web/hadid/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@4.9.5/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import nuxt_enterprise_plugin_H7G7mKigqA from "/builds/blintz/front-web/hadid/node_modules/.pnpm/@nargarath+vue-recaptcha@3.0.0-alpha.7_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@4.9.5_/node_modules/@nargarath/vue-recaptcha/dist/nuxt-enterprise-plugin.mjs";
import _00_libs_T2je4nhN4b from "/builds/blintz/front-web/hadid/hadid/plugins/00.libs.ts";
import _02_blockedRoutes_ofiuvz02JG from "/builds/blintz/front-web/hadid/hadid/plugins/02.blockedRoutes.ts";
import _04_sentry_client_tuXFV7LSkY from "/builds/blintz/front-web/hadid/hadid/plugins/04.sentry.client.ts";
import _05_integrationScripts_PGNpEOCB7R from "/builds/blintz/front-web/hadid/hadid/plugins/05.integrationScripts.ts";
export default [
  revive_payload_client_iLTtgTktL1,
  unhead_t68alr641N,
  router_4kKh0Mukew,
  payload_client_4sBnIbRdCM,
  navigation_repaint_client_Vh1dL4yNcG,
  check_outdated_build_client_JQOvDqJY2C,
  chunk_reload_client_5LM65pWYdC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Ai13c6FYUk,
  plugin_client_giO07cn1Lr,
  plugin_gkFrgOgGJt,
  nuxt_enterprise_plugin_H7G7mKigqA,
  _00_libs_T2je4nhN4b,
  _02_blockedRoutes_ofiuvz02JG,
  _04_sentry_client_tuXFV7LSkY,
  _05_integrationScripts_PGNpEOCB7R
]