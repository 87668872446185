import * as Sentry from '@sentry/vue';

import projectSetting from '@/package.json';

export default defineNuxtPlugin(({ vueApp, $config }) => {
	const { environment, isSentryEnabled, sentryDsn } = $config.public;

	// * Sentry não deve ser inicializado em ambiente de desenvolvimento para evitar que
	// * erros de desenvolvimento sejam enviados para o Sentry, a menos que a variável
	// * de ambiente IS_SENTRY_ENABLED esteja definida como true (o que só deve acontecer
	// * para podermos debuggar o Sentry em ambiente de desenvolvimento)
	if (environment === 'development' && !isSentryEnabled) return;

	Sentry.init({
		app: [vueApp],
		release: `hadid@${projectSetting.version}`,
		dsn: sentryDsn,
		environment,
		integrations: [
			Sentry.browserTracingIntegration({
				router: vueApp.$nuxt.$router
			}),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			})
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		autoSessionTracking: true,
		trackComponents: true
	});
});
