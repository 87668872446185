<template>
	<div class="modal-container">
		<div class="modal-content-wrapper" :data-variant="variant">
			<slot> </slot>

			<div v-if="variant === 'video-carousel'" class="buttons-wrapper">
				<button class="close-icon" type="button" @click="emit('close-modal')">
					<CloseIcon />
				</button>
			</div>
		</div>
		<div class="modal-backdrop" @click="emit('close-modal')"></div>
	</div>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core';

import type { ResponsiveSizeOrAuto, CssSize } from '@SHARED/utils/helperTypes';

import CloseIcon from '~icons/mdi/close';

defineOptions({ name: 'ModalContainer' });

type ModalContainerProps = {
	variant?: 'default' | 'video-carousel' | 'property-view';
	sizes?: {
		width: ResponsiveSizeOrAuto;
		height: ResponsiveSizeOrAuto;
	} | null;
};

type Emits = {
	(e: 'close-modal'): void;
};

const props = withDefaults(defineProps<ModalContainerProps>(), {
	sizes: null,
	variant: 'default'
});

const emit = defineEmits<Emits>();

onKeyStroke('Escape', () => emit('close-modal'));

type Size = CssSize | 'auto';

const desktopWidth = computed<Size>(() => {
	if (!props.sizes) return '600px';

	return props.sizes.width.desktop;
});

const desktopHeight = computed<Size>(() => {
	if (!props.sizes) return 'auto';

	return props.sizes.height.desktop;
});

const tabletWidth = computed<Size>(() => {
	if (!props.sizes?.width?.tablet) return '600px';

	return props.sizes.width.tablet;
});

const tabletHeight = computed<Size>(() => {
	if (!props.sizes?.height?.tablet) return 'auto';

	return props.sizes.height.tablet;
});

const mobileWidth = computed<Size>(() => {
	if (!props.sizes) return '90%';

	return props.sizes.width.mobile;
});

const mobileHeight = computed<Size>(() => {
	if (!props.sizes) return 'auto';

	return props.sizes.height.mobile;
});
</script>

<style lang="scss" scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 31;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-content-wrapper {
	&[data-variant='default'] {
		background-color: var(--white);
		position: fixed;
		top: 50%;
		left: 50%;
		width: v-bind(mobileWidth);
		height: v-bind(mobileHeight);
		z-index: 40;
		transform: translate(-50%, -50%);

		// <!-- TODO: avaliar melhor se essa mudança deveria ser feita aqui, ou em outro lugar -->
		@supports (height: 1dvh) {
			height: 100dvh;
		}

		@include screen-up(md) {
			width: v-bind(tabletWidth);
			height: v-bind(tabletHeight);
		}

		@include screen-up(lg) {
			width: v-bind(desktopWidth);
			height: v-bind(desktopHeight);
		}
	}

	&[data-variant='video-carousel'] {
		overflow: hidden;
		position: fixed;
		top: 0;
		height: 100vh;
		z-index: 40;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--white);
		padding-top: 3rem;
		padding-bottom: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;

		@include screen-up(lg) {
			padding: 0 8rem;
		}
	}

	&[data-variant='property-view'] {
		width: 100%;
		position: fixed;
		top: 0;
		height: 100%;
		z-index: 20;

		@include screen-up(lg) {
			width: calc(100% - 8.5rem);
			top: 3rem;
			left: 50%;
			transform: translateX(-50%);
		}

		+ .modal-backdrop {
			z-index: 15;
		}
	}
}
.buttons-wrapper {
	top: 0%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	position: absolute;
	z-index: 51;

	.buttons-container {
		width: 100%;
	}

	.close-icon {
		position: absolute;
		border: none;
		padding: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--black);
		top: 1.5rem;
		right: 1.25rem;
		transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

		&:hover {
			transform: scale(1.25);
		}

		svg {
			width: 2rem;
			height: 2rem;
		}
	}
}
</style>
