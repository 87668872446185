import type { Color } from '@SHARED/core/entities/WebsiteConfig';
import type { CSSPaddingSize, CssSize } from '@SHARED/utils/helperTypes';

export type CSSColorVar = `var(--${Color})`;

export function getCSSColorVar(key: Color): CSSColorVar {
	return `var(--${key})`;
}

// TODO: escrever testes
export function getCssNumberValueInPixels(value: string): number {
	const valueToFormat = value ?? 0;

	const numberValue = valueToFormat.replace(/[^\d.]/g, '');

	return valueToFormat.includes('rem')
		? parseFloat(numberValue) * 16
		: parseFloat(numberValue);
}

type Position = 'top' | 'right' | 'bottom' | 'left';

export function getPadding(
	position: Position,
	padding: CSSPaddingSize | null | undefined
): CssSize {
	if (!padding) return '0';

	const paddingValues: CssSize[] = padding.split(' ') as CssSize[];

	const positionIndexes: Record<Position, number> = {
		top: 0,
		right: 1,
		bottom: 2,
		left: 3
	};

	switch (paddingValues.length) {
		case 1:
			positionIndexes.right = 0;
			positionIndexes.bottom = 0;
			positionIndexes.left = 0;
			break;
		case 2:
			positionIndexes.bottom = 0;
			positionIndexes.left = 1;
			break;
		case 3:
			positionIndexes.left = 1;
			break;
		case 4:
			break;
		default:
			return '0';
	}

	return paddingValues[positionIndexes[position]] || '0';
}

export const DEFAULT_NUXT_IMAGE_PLACEHOLDER = [50, 50, 80, 1];
