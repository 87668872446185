<template>
	<Component
		:is="component"
		:to="url"
		:class="`button-block ${fontFamily}-font`"
		:data-rounded="buttonVariant"
		:data-outlined="isOutlined"
	>
		<slot name="before"> </slot>
		{{ text }}
		<slot></slot>
		<slot name="after"> </slot>
	</Component>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import type { ButtonBlock } from '@SHARED/core/entities/blocks/ButtonBlock';
import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize, FontWeight } from '@SHARED/utils/helperTypes';

import { getCSSVar } from '@SHARED/utils';
import { COLOR_SERVICE } from '@SHARED/utils/vueProvidersSymbols';

import { NuxtLink } from '#components';

defineOptions({ name: 'ButtonBlock' });

const styles = useState<WebsiteStyles>('styles');

type ButtonSizing = {
	padding: `${CssSize} ${CssSize}`;
	fontSize: CssSize;
};

type ButtonBlockProps = Omit<ButtonBlock['config'], 'text' | 'url'> & {
	text?: string | null;
	url?: string | null | RouteLocationRaw;
};

const props = withDefaults(defineProps<ButtonBlockProps>(), {
	variant: null,
	fontFamily: 'default',
	fontSize: null,
	fontWeight: null,
	size: 'medium',
	gap: 'initial',
	backgroundColor: null,
	textColor: null,
	url: null,
	text: null
});

const sizingDictionary: Record<string, ButtonSizing> = {
	small: {
		padding: '0.5rem 1rem',
		fontSize: '0.875rem'
	},
	medium: {
		padding: '0.75rem 1.5rem',
		fontSize: '1rem'
	},
	large: {
		padding: '1.25rem 2.5rem',
		fontSize: '1.25rem'
	}
};

const component = computed<typeof NuxtLink | 'button'>(() =>
	props.url ? NuxtLink : 'button'
);

const colorService = inject(COLOR_SERVICE)!;

const buttonHoverBackgroundColor = computed<string>(() => {
	const backgroundColor =
		props.backgroundColor || styles.value.appearance.background;

	const color = styles.value.colorPalette[backgroundColor];

	return colorService.getHoverHexColor(color);
});

const buttonVariant = computed<string>(
	() => props.variant || styles.value.appearance.defaultBorderRadius
);

const buttonBackgroundColor = computed<string>(() =>
	getCSSVar(props?.backgroundColor || styles.value.appearance.background)
);

const buttonTextColor = computed<string>(() => {
	if (props.textColor) return getCSSVar(props.textColor);

	const backgroundColor =
		props.backgroundColor || styles.value.appearance.background || 'primary';

	const backgroundColorValue = styles.value.colorPalette[backgroundColor];

	return colorService.getTextColorForBackground(backgroundColorValue);
});

const desktopFontSize = computed<CssSize>(() => {
	if (props.fontSize) return props.fontSize.desktop;

	return sizingDictionary[props.size].fontSize;
});

const mobileFontSize = computed<CssSize>(() => {
	if (props.fontSize) return props.fontSize.mobile;

	return sizingDictionary[props.size].fontSize;
});

const desktopFontWeight = computed<FontWeight>(
	() => props.fontWeight?.desktop || 400
);

const mobileFontWeight = computed<FontWeight>(
	() => props.fontWeight?.mobile || 400
);

const padding = computed(() => sizingDictionary[props.size].padding);

const verticalPadding = computed<string>(() => padding.value.split(' ')[0]);

const horizontalPadding = computed<string>(() => padding.value.split(' ')[1]);

const desktopButtonWidth = computed(() => props.width?.desktop || 'auto');

const mobileButtonWidth = computed(() => props.width?.mobile || 'auto');
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/borderRadius.scss';

.button-block {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: v-bind(gap);
	background-color: v-bind(buttonBackgroundColor);
	color: v-bind(buttonTextColor);
	padding: v-bind(padding);
	font-size: v-bind(mobileFontSize);
	font-weight: v-bind(mobileFontWeight);
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	width: v-bind(mobileButtonWidth);

	@include screen-up(lg) {
		width: v-bind(desktopButtonWidth);
		font-size: v-bind(desktopFontSize);
		font-weight: v-bind(desktopFontWeight);
	}

	&[data-outlined='true'] {
		--outline-border-size: 2px;

		background-color: transparent;
		color: v-bind(buttonBackgroundColor);
		border: var(--outline-border-size) solid v-bind(buttonBackgroundColor);
		padding: calc(v-bind(verticalPadding) - var(--outline-border-size))
			calc(v-bind(horizontalPadding) - var(--outline-border-size));

		&:hover {
			border: var(--outline-border-size) solid
				v-bind(buttonHoverBackgroundColor);
			color: v-bind(buttonTextColor);
			background-color: v-bind(buttonBackgroundColor);
		}
	}

	&:hover {
		background-color: v-bind(buttonHoverBackgroundColor);
	}

	@include border-radius-size();

	&:disabled {
		cursor: not-allowed;
	}
}

.dropdown-button {
	overflow: hidden;
	max-width: 10rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	padding-right: 2rem;
	border: solid 2px transparent;

	&.active {
		border-color: var(--primary);
	}
}
</style>
