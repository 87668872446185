import type { Validation } from '@vuelidate/core';
import { useRecaptcha } from '@SHARED/composables/useRecaptcha';
import { copyObject } from '@SHARED/utils';

export type FormSubmitionFunctionParams = {
	recaptchaToken: string;
};

type UseFormWithRecaptchaParams<FormData> = {
	formInitialState: FormData;
	formData: FormData; // * Esse dado deve ser um reactive, mas a tipagem do reactive é a mesma de um objeto comum, por isso não há uma indicação de que é um reactive direto no tipo
	vuelidate: Ref<Validation>;
	formSubmitionFunction: (params: FormSubmitionFunctionParams) => Promise<void>;
	onError: (error: unknown) => void;
	shouldSubmitForm?: () => boolean;
};

type UseFormWithRecaptchaReturn = {
	isSubmittingForm: Ref<boolean>;
	submitForm: () => Promise<void>;
	recaptchaRoot: Ref<Element | undefined>;
	resetForm: () => void;
};

export function useFormWithRecaptcha<FormData extends Record<string, any>>({
	formInitialState,
	formData,
	vuelidate,
	formSubmitionFunction,
	onError,
	shouldSubmitForm: shouldSubmitFormGetter
}: UseFormWithRecaptchaParams<FormData>): UseFormWithRecaptchaReturn {
	const isSubmittingForm = ref<boolean>(false);

	const {
		recaptchaToken,
		recaptchaRoot,
		resetRecaptcha,
		executeRecaptchaChallenge
	} = useRecaptcha({ onVerified: submitForm });

	function resetForm() {
		Object.assign(formData, copyObject(formInitialState));
		vuelidate.value.$reset();
		resetRecaptcha();
		isSubmittingForm.value = false;
	}

	const shouldSubmitForm = computed<boolean>(
		shouldSubmitFormGetter ?? (() => true)
	);

	async function submitForm() {
		try {
			if (vuelidate.value.$error) return;

			if (!shouldSubmitForm.value) return;

			isSubmittingForm.value = true;

			await formSubmitionFunction({
				recaptchaToken: recaptchaToken.value as string
			});

			resetForm();
		} catch (error) {
			onError(error);
			resetRecaptcha();
		} finally {
			isSubmittingForm.value = false;
		}
	}

	async function validateAndSubmit() {
		const isValid = await vuelidate.value.$validate();

		if (!isValid) return;

		if (recaptchaToken.value) {
			await submitForm();
		} else {
			executeRecaptchaChallenge();
		}
	}

	return {
		isSubmittingForm,
		submitForm: validateAndSubmit,
		recaptchaRoot,
		resetForm
	};
}
