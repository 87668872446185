import type { PropertiesRepository } from '@SHARED/core/ports/repositories/PropertiesRepository';
import { Result } from '@SHARED/core/helpers/result';

export const nuxtPropertiesRepository: PropertiesRepository = {
	async getPropertiesSharedAtCompanyWebsite({
		domain,
		maxQuantity,
		filters,
		offset
	}) {
		try {
			const query = {
				maxQuantity,
				offset,
				filters
			};

			const properties = await $fetch(`/api/websites/${domain}/properties`, {
				query,
				headers: { host: domain }
			});

			return Result.ok(
				properties || {
					filteredCount: 0,
					properties: [],
					totalCount: 0
				}
			);
		} catch (error) {
			return Result.error({
				message: `Error while fetching properties for domain ${domain}`,
				source: 'nuxtPropertiesRepository.getPropertiesSharedAtCompanyWebsite',
				originalErrorObject: error as Error
			});
		}
	},
	async getPropertyBySlug({ domain, slug }) {
		try {
			const property = await $fetch(
				`/api/websites/${domain}/properties/${slug}`,
				{ headers: { host: domain } }
			);

			return Result.ok(property || null);
		} catch (error) {
			if ((error as any)?.response?.status === 404) return Result.ok(null);

			return Result.error({
				message: `Error while fetching single property with slug ${slug}`,
				source: 'nuxtPropertiesRepository.getPropertyBySlug',
				originalErrorObject: error as Error
			});
		}
	},
	async getAvailablePropertyAddresses({ domain }) {
		try {
			const regionsByCity = await $fetch(
				`/api/websites/${domain}/properties/addresses`,
				{ headers: { host: domain } }
			);

			if (typeof regionsByCity === 'string') {
				return Result.error({
					message: `No property addresses found for domain ${domain}`,
					source: 'nuxtPropertiesRepository.getAvailablePropertyAddresses'
				});
			}

			return Result.ok(regionsByCity);
		} catch (error) {
			return Result.error({
				message: `Error while fetching property addresses for domain ${domain}`,
				source: 'nuxtPropertiesRepository.getAvailablePropertyAddresses',
				originalErrorObject: error as Error
			});
		}
	},
	async getAvailablePropertyTypes({ domain }) {
		try {
			const propertyTypes = await $fetch(
				`/api/websites/${domain}/properties/property-types`,
				{ headers: { host: domain } }
			);

			return Result.ok(propertyTypes || []);
		} catch (error) {
			return Result.error({
				message: `Error while fetching property types for domain ${domain}`,
				source: 'nuxtPropertiesRepository.getAvailablePropertyTypes',
				originalErrorObject: error as Error
			});
		}
	},
	async getRelatedPropertiesDataInPropertyRegion({
		domain,
		region,
		city = null
	}) {
		try {
			const relatedData = await $fetch(
				`/api/websites/${domain}/properties/related-data`,
				{
					query: { region, city },
					headers: { host: domain }
				}
			);

			if (typeof relatedData === 'string') {
				return Result.error({
					message: `No related properties data found for domain ${domain}`,
					source:
						'nuxtPropertiesRepository.getRelatedPropertiesDataInPropertyRegion'
				});
			}

			return Result.ok(relatedData);
		} catch (error) {
			return Result.error({
				message: `Error while fetching related properties data for domain ${domain}`,
				source:
					'nuxtPropertiesRepository.getRelatedPropertiesDataInPropertyRegion',
				originalErrorObject: error as Error
			});
		}
	},
	getSitemapPropertiesSharedAtCompanyWebsite() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source:
					'nuxtPropertiesRepository.getSitemapPropertiesSharedAtCompanyWebsite',
				originalErrorObject: null
			})
		);
	}
};
